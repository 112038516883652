import { FaMicrophoneLines, FaUpload } from "react-icons/fa6";
import { BiSolidCustomize } from "react-icons/bi";
import { IoOptions } from "react-icons/io5";

import { ReactComponent as ScrybeLogo } from '../assets/Scrybe_Logo.svg';
import { ReactComponent as WizardMicrophoneIcon } from '../assets/WizardMicrophoneIcon.svg';
import { PiUploadSimpleFill } from "react-icons/pi";
import { IoIosMusicalNotes } from "react-icons/io";



import React from 'react';

const Steps = () => {

    return (
        // <!-- Features -->

        <div class="relative">
            {/* <!-- Tab Navs --> */}
            <nav class="grid gap-4" aria-label="Tabs" role="tablist">
                <div type="button" class="bg-white shadow-md border text-start p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 active">
                    <span class="flex">
                        <WizardMicrophoneIcon className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" />

                        <span class="grow ms-6">
                            <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">1. Record session</span>
                            <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">Record your sessions using a phone or any device with a microphone. Don't worry about audio quality or background noise!</span>
                        </span>
                    </span>
                </div>

                <div type="button" class="bg-white shadow-md border text-start p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                    <span class="flex">
                        <PiUploadSimpleFill className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" />
                        <span class="grow ms-6">
                            <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">2. Upload to Scrybe</span>
                            <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">Easily upload your recorded session with just a few clicks. Scrybe accepts a wide range of audio formats.</span>
                        </span>
                    </span>
                </div>

                <div type="button" class="bg-white shadow-md border text-start p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                    <span class="flex">
                        <IoIosMusicalNotes className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" />
                        <span class="grow ms-6">
                            <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">3. Choose narrator and music </span>
                            <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">Select from a variety of narrators and background music to bring your recap to life. Customize your recap to match the mood of your session.</span>
                        </span>
                    </span>
                </div>

                <div type="button" class="bg-white shadow-md border text-start p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                    <span class="flex">
                        <ScrybeLogo className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200" />
                        <span class="grow ms-6">
                            <span class="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 dark:hs-tab-active:text-blue-500 dark:text-gray-200">4. Done!</span>
                            <span class="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">Your game session is now immortalized with a captivating audio/video recap! Unveil it to your party at the beginning of the next session to pick up where you left off.</span>
                        </span>
                    </span>
                </div>
            </nav>
            {/* <!-- End Tab Navs --> */}
        </div>
    );
};

export default Steps;
