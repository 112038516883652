import React, { useState, useEffect, useRef } from 'react';
import { auth, storage, db } from '../firebaseConfig';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import TextBox from '../components/TextBox';
import BreadCrumb from "../components/BreadCrumb";
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import moment from 'moment';

import { BiSolidCopy } from 'react-icons/bi';
import { FaSyncAlt } from "react-icons/fa";
import { IoMusicalNotes } from "react-icons/io5";

import { ReactComponent as ScrybeLogo } from '../assets/Scrybe_Logo.svg';
import { ReactComponent as WizardMicrophoneIcon } from '../assets/WizardMicrophoneIcon.svg';


import LandingPageGraphicV2 from '../assets/LandingPageGraphicV2.svg';


import EditableTextBox from '../components/EditableTextBox';
import CharacterCount from '@tiptap/extension-character-count';

async function generateDownloadLink(filePath) {

    // Create a reference to the file in Storage
    const fileRef = ref(storage, filePath);

    // Generate the download URL
    return getDownloadURL(fileRef)
        .then((url) => {
            return url;
        })
        .catch((error) => {
            // Handle any errors
            console.error("Error generating download link: ", error);
        });

}

function SessionPage() {

    const { campaignId } = useParams();
    const { sessionId } = useParams();

    const [sessionData, setSessionData] = useState({});

    const [currentRecap, setCurrentRecap] = useState(1);

    const [narrationAudioURL, setNarrationAudioURL] = useState("");
    const [narrationVideoURL, setNarrationVideoURL] = useState("");

    const [sessionName, setSessionName] = useState(null);
    const [sessionDate, setSessionDate] = useState(null);
    const [transcription, setTranscription] = useState(null);

    const [summary, setSummary] = useState(null);
    const [originalSummary, setOriginalSummary] = useState(null);

    const [manualEdit, setManualEdit] = useState(false);


    const [narrator, setNarrator] = useState(null);
    const [backgroundMusicName, setBackgroundMusicName] = useState(null);
    const [backgroundMusicPath, setBackgroundMusic] = useState(null);


    // -------------- EDITOR -------------- 
    const [editorContent, setEditorContent] = useState("");

    const editor = useEditor({
        extensions: [StarterKit, CharacterCount.configure({ limit: 2500})], //MAX CHARACTER LIMIT
        onUpdate({ editor }) {
            setEditorContent(editor.getText());
        },
    });

    function textToHTML(text) {
        return text.split('\n').map(line => `<p>${line}</p>`).join('')
    }

    useEffect(() => {
        if (editor) {
            // Swap new lines for <p> tags
            const formattedSummary = textToHTML(summary);
            editor.commands.setContent(formattedSummary);
        }
    }, [summary]);


    // Usestate for getting transcription from firebase
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // Reference to the session document
                const sessionDocRef = doc(db, `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions/${sessionId}`);

                // Attaching the listener with onSnapshot
                const unsubscribeDoc = onSnapshot(sessionDocRef, async (doc) => {
                    if (doc.exists()) {
                        const tempSessionData = doc.data();
                        setSessionName(tempSessionData.name);
                        setSessionDate(moment(tempSessionData.timeCreated.toDate()).format('MMMM Do, YYYY'));
                        setTranscription(tempSessionData.transcription);
                        setSummary(tempSessionData.recaps.recap1.summary);

                        setEditorContent(tempSessionData.recaps.recap1.summary);

                        setOriginalSummary(tempSessionData.recaps.recap1.summary);
                        setNarrator(tempSessionData.recaps.recap1.narrator);
                        setBackgroundMusicName(tempSessionData.recaps.recap1.backgroundMusicName);
                        setBackgroundMusic(tempSessionData.recaps.recap1.backgroundMusicPath);

                        setManualEdit(tempSessionData.recaps.recap1.manualEdit);

                        // If there is a narration, get the download link
                        if (tempSessionData.recaps.recap1.narrationStoragePath) {
                            setNarrationAudioURL(await generateDownloadLink(tempSessionData.recaps.recap1.narrationStoragePath));
                        }

                        if (tempSessionData.recaps.recap1.narratedVideoStoragePath) {
                            setNarrationVideoURL(await generateDownloadLink(tempSessionData.recaps.recap1.narratedVideoStoragePath));
                        }

                        setSessionData(doc.data());

                    } else {
                        // Handle the case where the document does not exist
                        console.log("No such document!");
                    }
                }, (error) => {
                    console.error("Error listening to session updates: ", error);
                });

                // Return the cleanup function
                return () => {
                    unsubscribeDoc(); // Unsubscribe from document changes
                };
            } else {
                // User is signed out
                setTranscription([]);
                setSummary([]);
                setNarrator(null);
            }
        });

        // Cleanup the auth state listener on component unmount
        return () => unsubscribe();
    }, []);


    const regenerateRecap = async () => {

        const endpoint = 'https://us-central1-dungeon-copilot-6065c.cloudfunctions.net/regenerate_recap';

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userID: auth.currentUser.uid,
                campaignID: campaignId,
                sessionID: sessionId,
            }),
        });
    }

    const generateRecapFromSummary = async () => {

        // Set the manual edit to false in the database
        const sessionDocRef = doc(db, `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions/${sessionId}`);

        await updateDoc(sessionDocRef, {
            [`recaps.recap${currentRecap}.manualEdit`]: false,
        });

        const endpoint = 'https://us-central1-dungeon-copilot-6065c.cloudfunctions.net/generate_recap_from_summary';

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userID: auth.currentUser.uid,
                campaignID: campaignId,
                sessionID: sessionId,
            }),
        });
    }

    const nextRecap = () => {
        setCurrentRecap(currentRecap + 1);

        setSummary(sessionData.recaps[`recap${currentRecap + 1}`].summary);

        setNarrationAudioURL(generateDownloadLink(sessionData.recaps[`recap${currentRecap + 1}`].narrationStoragePath));


    }


    const handleSaveChanges = async () => {
        // Reference to the session document (updateDoc)
        const sessionDocRef = doc(db, `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions/${sessionId}`);


        editor.commands.setContent(editorContent.trim());

        // Update the summary in the database
        await updateDoc(sessionDocRef, {
            [`recaps.recap${currentRecap}.summary`]: editorContent.trim(),
        });


        // Update the state of the original summary
        setOriginalSummary(editorContent.trim());

    }

    const handleDiscardChanges = () => {
        const formattedSummary = textToHTML(originalSummary);
        editor.commands.setContent(formattedSummary);
        setEditorContent(originalSummary);
    };







    return (
        <div>

            <div class="max-w-7xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
                <div class="max-w-2xl mx-auto text-center mb-10 md:mb-14">
                    <h1 class="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut max-w-md mx-auto">{sessionName}</h1>
                    <p class="mt-1 text-gray-600 dark:text-gray-400 max-w-xs mx-auto">
                        {sessionDate}
                    </p>

                    <div class="mt-1">
                        {/* Status Spinner */}
                        {sessionData?.transcription === null &&
                            <div class="flex items-center justify-center space-x-3">
                                <span class="text-xs whitespace-nowrap">Transcribing</span>
                                <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-blue-800 rounded-full" role="status" aria-label="loading"></div>
                            </div>
                        }

                        {sessionData?.transcription !== null && sessionData?.recaps?.recap1?.summary === null &&
                            <div class="flex items-center justify-center space-x-3">
                                <span class="text-xs whitespace-nowrap">Summarizing</span>
                                <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-rose-500 rounded-full" role="status" aria-label="loading"></div>
                            </div>
                        }

                        {sessionData?.transcription !== null && sessionData?.recaps?.recap1?.summary !== null && manualEdit &&
                            <div class="flex items-center justify-center space-x-2">
                                <span class="text-xs whitespace-nowrap">Review Recap</span>
                                <ScrybeLogo className='w-5 h-5' />
                            </div>
                        }

                        {sessionData?.transcription !== null && sessionData?.recaps?.recap1?.summary !== null && sessionData?.recaps?.recap1?.narrationStoragePath === null && !manualEdit &&
                            <div class="flex items-center justify-center space-x-3">
                                <span class="text-xs whitespace-nowrap">Narrating</span>
                                <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-yellow-500 rounded-full" role="status" aria-label="loading"></div>
                            </div>
                        }

                        {sessionData?.transcription !== null && sessionData?.recaps?.recap1?.summary !== null && sessionData?.recaps?.recap1?.narrationStoragePath !== null && sessionData?.recaps?.recap1?.narratedVideoStoragePath === null &&
                            <div class="flex items-center justify-center space-x-3">
                                <span class="text-xs whitespace-nowrap">Generating Video</span>
                                <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-green-500 rounded-full" role="status" aria-label="loading"></div>
                            </div>
                        }
                    </div>




                </div>



                <div>

                    <div class="justify-center pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
                        <h2 className="text-xl font-semibold text-center">Transcription</h2>
                    </div>
                    {transcription ? (
                        <TextBox
                            // title="Transcription"
                            content={transcription}
                        />
                    ) : (
                        <ul class="space-y-3 animate-pulse">
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                        </ul>
                    )}
                </div>

                <div>
                    <div class="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
                        <h2 className="text-xl font-semibold text-center">Text Recap</h2>
                    </div>
                    {editorContent !== null && editorContent !== undefined ? (

                        <>
                            <div className='dots-corners text-sm border border-y-gray-200 w-full flex flex-col bg-white border-x-2 border-black p-4 '>
                                <EditorContent editor={editor} className spellcheck="false" />
                            </div>

                            {/* If summary and  original summary are not equal, show the save button */}
                            {editorContent !== originalSummary && (
                                <div class="mt-5 flex justify-end gap-x-2">
                                    <button type="button" onClick={handleDiscardChanges} class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                        Discard changes
                                    </button>
                                    <button id="session-submit-btn" onClick={handleSaveChanges} type="submit" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-black text-white hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                        Save
                                    </button>
                                </div>
                            )}




                            {manualEdit && (

                                <div className="flex justify-center w-full mt-10">
                                    <button onClick={generateRecapFromSummary} class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-black text-white hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                        Continue Generation
                                    </button>
                                </div>
                            )}

                        </>
                    ) : (
                        <ul class="space-y-3 animate-pulse">
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                        </ul>
                    )}
                </div>

                <div >
                    <div class="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
                        <h2 className="text-xl font-semibold text-center">Audio Recap</h2>
                    </div>
                    {narrationAudioURL ? (

                        <audio controls className="mx-auto">
                            <source src={narrationAudioURL} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>

                    ) : (
                        <ul class="space-y-3 animate-pulse">
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                        </ul>
                    )}

                </div>

                <div >
                    <div class="justify-center pt-16 pb-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 font-inknut">
                        <h2 className="text-xl font-semibold text-center">Video Recap</h2>
                    </div>
                    {narrationVideoURL ? (
                        <>
                            <video controls className="mx-auto shadow-2xl rounded-3xl w-[40rem]">
                                <source src={narrationVideoURL} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </video>
                        </>
                    ) : (
                        <ul class="space-y-3 animate-pulse">
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                            <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                        </ul>
                    )}
                </div>


                {/*<!-- Icon Blocks -->*/}
                <div class="grid sm:grid-cols-2 lg:grid-cols-2 gap-4 sm:gap-12 mt-12">
                    {/*<!-- Icon Block -->*/}
                    <div class="text-center rounded-md border border-gray-200 p-5">
                        <div class="flex justify-center items-center w-8 h-8 bg-gray-50 border border-gray-200 rounded-full mx-auto dark:bg-neutral-800 dark:border-neutral-700">
                            <WizardMicrophoneIcon className="h-4 w-4" />
                        </div>
                        <div class="mt-3">
                            <h3 class="text-sm flex justify-center font-semibold text-gray-800 dark:text-white">{narrator ? narrator.name : "Narrator"}</h3>
                            {/* <p class="mt-1 text-gray-600 dark:text-neutral-400">Responsive, and mobile-first project on the web</p> */}
                        </div>
                    </div>
                    {/*<!-- End Icon Block -->*/}

                    {/*<!-- Icon Block -->*/}
                    <div class="text-center rounded-md border border-gray-200 p-5">
                        <div class="flex justify-center items-center w-8 h-8 bg-gray-50 border border-gray-200 rounded-full mx-auto dark:bg-neutral-800 dark:border-neutral-700">
                            <IoMusicalNotes className="h-4 w-4 " />
                        </div>
                        <div class="mt-3">
                            <h3 class="text-sm flex justify-center font-semibold text-gray-800 dark:text-white">{backgroundMusicName ? backgroundMusicName : "Background Music"}</h3>
                            {/* <p class="mt-1 text-gray-600 dark:text-neutral-400">Components are easily customized and extendable</p> */}
                        </div>
                    </div>
                    {/*<!-- End Icon Block -->*/}

                </div>

                {/*<!-- End Icon Blocks -->*/}


                {/* Regenerate Recap */}
                {/* <div class="mt-10 flex
justify-center gap-x-2">
                    <button onClick={regenerateRecap} class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                        <FaSyncAlt className="h-5 w-5" />
                        Regenerate Recap
                    </button>
                    <button onClick={nextRecap} class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                        Next Recap
                    </button>

                </div> */}


            </div>

        </div>
    );
}

export default SessionPage;