import React, { useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, storage, db } from '../firebaseConfig';
import { BiLogIn, BiLogOut } from 'react-icons/bi';
import { getDoc, doc, addDoc, setDoc, collection, onSnapshot } from "firebase/firestore";
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as Icon } from '../assets/Scrybe_Logo.svg';
import UploadSession from './UploadSession'

// Icons
import { AiOutlineFire, AiOutlineUser } from 'react-icons/ai';
import { GiQuillInk, GiScrollQuill, GiScrollUnfurled, GiWizardFace, GiPointyHat, GiBackpack, GiBindle } from 'react-icons/gi';
import { ImQuill } from 'react-icons/im';
import { FaQuestionCircle } from "react-icons/fa";
import { IoMail, IoMusicalNotes } from "react-icons/io5";
import { FaDroplet, FaDollarSign } from "react-icons/fa6";





const Navbar = () => {

    const [tokens, setTokens] = React.useState(0);
    const [currUser, setCurrUser] = React.useState(null);
    const [animateToken, setAnimateToken] = React.useState(false);

    let navigate = useNavigate();

    // Get token count from firestore database
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (!auth.currentUser) {
                // console.log('User not authenticated');
                setCurrUser(null);
            } else {
                // console.log('User authenticated');
                // console.log(auth.currentUser.uid);
                // console.log(auth.currentUser.displayName);
                // console.log(auth.currentUser.email)
                setCurrUser(auth.currentUser);

                // Set up a real-time listener
                const unsubscribeDoc = onSnapshot(doc(db, `users/${auth.currentUser.uid}`), (doc) => {
                    const userData = doc.data();
                    if (userData && 'tokenBalance' in userData) {
                        setTokens(userData.tokenBalance);
                    } else {
                        // Handle the case where tokenBalance is not yet set.
                        console.log('Token balance not available or document does not exist');
                        // You might want to handle this case differently depending on your needs.
                    }
                }, (error) => {
                    // Handle errors here, such as by logging them or showing a user-friendly message.
                    console.error("Error listening to user document:", error);
                });

                // Return the unsubscribe function for the document listener
                return () => {
                    unsubscribeDoc();
                };
            }
        });

        // Return the unsubscribe function for the auth listener
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        setAnimateToken(true);
        setTimeout(() => { setAnimateToken(false) }, 1000);
    }, [tokens]);

    const handleGoogle = async (e) => {
        const provider = await new GoogleAuthProvider();
        await signInWithPopup(auth, provider).then(async (result) => {
            // Add user to firestore database
            const user = result.user;

            const userRef = doc(db, `users`, user.uid);

            // Check if user exists in database
            const docSnap = await getDoc(userRef);

            if (docSnap.exists()) {
                console.log('User already exists');
                return;
            }

            await setDoc(userRef, { tokenBalance: 1 }, { merge: true });
            await setDoc(userRef, { email: user.email }, { merge: true });
            await setDoc(userRef, { displayName: user.displayName }, { merge: true });
            await setDoc(userRef, { createdAt: new Date() }, { merge: true });

            navigate('/');

        }
        ).catch((error) => {
            console.log(error);
        }
        );
    }

    const handleSignOut = async (e) => {
        auth.signOut();
        setTokens(0);
        navigate('/');
    }

    return (
        <header class="relative z-50 sticky top-0 shadow-lg w-full bg-white text-sm p-4 sm:p-8 dark:bg-gray-800">

            <nav class="flex justify-between" aria-label="Global">

                {/* LEFT SECTION */}
                <div class="flex items-center grow basis-0">
                    <Link to="/" class="flex items-center gap-x-2 relative">
                        <Icon class="w-10 h-10" />
                        <a class="flex items-center text-xl leading-none font-semibold dark:text-white text-primary-light hidden sm:block font-inknut" href="#" aria-label="Brand">Scrybe</a>
                        <span class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-blue-100 text-blue-800 rounded-full dark:bg-blue-500/10 dark:text-blue-500">
                            {/* <svg class="flex-shrink-0 w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" x2="12" y1="2" y2="6" /><line x1="12" x2="12" y1="18" y2="22" /><line x1="4.93" x2="7.76" y1="4.93" y2="7.76" /><line x1="16.24" x2="19.07" y1="16.24" y2="19.07" /><line x1="2" x2="6" y1="12" y2="12" /><line x1="18" x2="22" y1="12" y2="12" /><line x1="4.93" x2="7.76" y1="19.07" y2="16.24" /><line x1="16.24" x2="19.07" y1="7.76" y2="4.93" /></svg> */}
                            Alpha
                        </span>
                    </Link>
                </div>

                {/* MIDDLE SECTION */}
                <div class="flex items-center gap-x-10">
                    <UploadSession currUser={currUser}/>
                    {/* Conditionally Render if currUser */}
                    {/* {currUser != null ? (
                        <div>
                        </div>
                    ) : (
                        
                        <>

                        </>

                    )} */}
                </div>

                {/* RIGHT SECTION */}
                <div class="flex-grow basis-0 flex items-center justify-end space-x-2 sm:space-x-4">

                    {currUser != null ? (

                        <>

                            {/* <button type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                + Upload Session
                            </button> */}
                            <Link to="/pricing" class="relative flex flex-col items-center font-medium text-primary-light hover:text-gray-400 text-center dark:text-gray-400 dark:hover:text-gray-500">

                                {animateToken && (
                                    <FaDroplet class="flex w-3 h-3 animate-ping absolute " />
                                )}
                                <FaDroplet class="flex w-3 h-3" />
                                <p class="font-medium text-xs leading-none text-center mx-auto ">{tokens}</p>
                            </Link>

                            <div class="hs-dropdown relative inline-flex">
                                <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle py-2 px-3 sm:py-3 sm:px-4 inline-flex items-center gap-x-2 text-sm text-white font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black  disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                    <GiPointyHat class="w-5 h-5" />
                                    <svg class="hs-dropdown-open:rotate-180 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6" /></svg>
                                </button>

                                <div class="z-50 hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden w-[15rem] bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border dark:border-gray-700" aria-labelledby="hs-dropdown-with-header">
                                    <div class="py-3 px-5 -m-2 bg-gray-100 rounded-t-lg dark:bg-gray-700">
                                        <p class="text-sm text-gray-500 dark:text-gray-400">Signed in as</p>
                                        <p class="truncate text-sm font-medium text-gray-800 dark:text-gray-300">{currUser.email}</p>
                                    </div>
                                    <div class="mt-2 py-2 first:pt-0 last:pb-0">
                                        <Link to="/campaigns/recaps" class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
                                            <GiScrollQuill class="w-4 h-4" />
                                            My Recaps
                                        </Link>

                                        <Link to="/faq" class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
                                            <FaQuestionCircle class="w-4 h-4" />
                                            FAQ
                                        </Link>
                                        <Link to="/pricing" class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
                                            <FaDollarSign class="w-4 h-4" />
                                            Pricing
                                        </Link>
                                        <Link to="/credit" class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
                                            <IoMusicalNotes class="w-4 h-4" />
                                            Music Credit
                                        </Link>
                                        <Link to="/contact" class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
                                            <IoMail class="w-4 h-4" />
                                            Contact
                                        </Link>
                                        {/* <Link to="/campaigns" class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
                                            <GiBackpack class="w-4 h-4" />
                                            My Campaigns
                                        </Link> */}
                                        <button onClick={handleSignOut} type="button" class="flex items-center w-full gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700" href="#">
                                            <BiLogOut class="w-4 h-4" />
                                            Log Out
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </>

                    ) : (
                        <>

                            {/* <Link to="/faq" class="flex items-center gap-x-3.5 py-2 px-1 lg:px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700">
                                <FaQuestionCircle class="w-4 h-4" />
                                Samples
                            </Link> */}

                            <div class="hidden md:flex gap-1">
                                <Link to="/pricing" class="flex items-center gap-x-3.5 py-2 px-1 lg:px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700">
                                    {/* <FaQuestionCircle class="w-4 h-4" /> */}
                                    Pricing
                                </Link>
                                <Link to="/faq" class="flex items-center gap-x-3.5 py-2 px-1 lg:px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700">
                                    {/* <FaQuestionCircle class="w-4 h-4" /> */}
                                    FAQ
                                </Link>
                            </div>

                            <button onClick={handleGoogle} class="flex items-center whitespace-nowrap gap-x-2 font-medium hover:text-gray-500 sm:border-gray-300 md:ps-4 lg:ps-6 md:border-s dark:border-gray-700 dark:text-gray-400 dark:hover:text-blue-500">
                                Log in
                            </button>
                            <button onClick={handleGoogle} class="py-3 px-4 inline-flex items-center whitespace-nowrap gap-x-2 text-sm font-medium rounded-full bg-black text-white shadow-sm bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black  disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                Sign up
                            </button>
                        </>
                    )}
                </div>
            </nav>

            {/* {currUser != null && (
            <div class="sm:hidden">
                <hr class="my-4 border-gray-200 dark:border-gray-700" />
                <UploadSession />
            </div>
            )} */}
        </header>


    );
};

export default Navbar;
