import { useParams, Link } from 'react-router-dom';
import { auth, storage, db } from '../firebaseConfig';
import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { doc, getDoc, setDoc, deleteDoc, updateDoc, collection, getDocs, addDoc, Timestamp, orderBy, query, onSnapshot } from 'firebase/firestore';

import { ReactComponent as ScrybeLogo } from '../assets/Scrybe_Logo.svg';


import moment from 'moment';

import { modifyTokenBalance } from '../utils/FirebaseFunctionsHelper';

import { ElevenLabsTTS } from '../utils/ElevenLabsTTS';


import BreadCrumb from '../components/BreadCrumb';
import TextBox from '../components/TextBox';

import { useSession } from '../SessionContext';

import { IoCloseCircle, IoTrashSharp } from "react-icons/io5";



import { deleteSession } from '../utils/FirebaseFunctionsHelper';




// Narrator Dictionary
const narratorDict = {
  Josha: "nyeXGhluSezAnVjsQsbQ",
  Ixel: "Z77SbP4Tp0Wb71ywQ35v",
  Victoria: "fnHrJf6lxn8qpNfdSuAu",
  Oliver: "PgMyeAGf9RWm0c1wRJMB",
  Ella: "5yz1y2zDf5iCaWq5YSqP",
  Joanne: "j1xLWTLKxEl0e6WENGs0",
  Cecile: "bvz5eNAMkfKPt7uTLym7",
  Jami: "lUE3vcpcTVGfJ7KVgjbA",
  Oswald: "UMkQssU5lwO9uLwTkGIh",
  Paddington: "Z3jmT340rOZGuYHJ1FSV",
};

// Background Music Dictionary
const backgroundMusicDict = {
  "Adventure": "background_music_default.mp3",
  "Tavern": "background_music_tavern.mp3",
  "Tense": "background_music_tense.mp3",
  "Gala": "background_music_gala.mp3",
  "Mysterious": "background_music_mysterious.mp3",
  "Gothic": "background_music_gothic.mp3",
  "Wonder": "background_music_wonder.mp3",
  "Desert": "background_music_desert.mp3",
  "Eldritch Jazz": "background_music_eldritch_jazz.mp3",
  "Space Journey": "background_music_space_journey.mp3",
};


async function fetchCampaignData(campaignId) {
  try {

    // Fetch the campaign document from Firestore
    const docRef = await getDoc(doc(db, `users/${auth.currentUser.uid}/campaigns/${campaignId}`));

    if (docRef.exists) {
      return docRef.data();
    } else {
      console.error('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching campaign data:', error);
    return null;
  }
}


function CampaignPage() {
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState(null);

  const [sessions, setSessions] = useState(null);
  const [sessionName, setSessionName] = useState('');
  const [sessionDescription, setSessionDescription] = useState('');
  const [sessionImage, setSessionImage] = useState(null);

  const [whisperData, setWhisperData] = useState([]);
  const [summaryPointFormData, setSummaryPointFormData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);


  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);


  const [sessionsStatus, setSessionsStatus] = useState({});

  const [narrator, setNarrator] = useState('Josha');
  const [backgroundMusic, setBackgroundMusic] = useState('Adventure');

  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedSessionId, setSelectedSessionId] = useState('');

  // Scroll to the top of the page when the component is mounted
  // window.scrollTo(0, 0); // Scroll to the top of the page

  useEffect(() => {
    // Assuming sessions is a state variable that, when changed, should update session status
    if (!sessions) return;
    sessions.forEach(session => {
      const unsub = onSnapshot(doc(db, `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions`, session.id), (doc) => {
        const data = doc.data();

        setSessionsStatus(prevStatus => ({
          ...prevStatus,
          [doc.id]: {
            transcribing: data?.transcription === null,
            summarizing: data?.transcription !== null && data?.recaps?.recap1?.summary === null,
            waitingForManualEdit: data?.transcription !== null && data?.recaps?.recap1?.summary !== null && data?.recaps?.recap1?.manualEdit == true,
            narrating: data?.transcription !== null && data?.recaps?.recap1?.summary !== null && data?.recaps?.recap1?.narrationStoragePath === null && data?.recaps?.recap1?.manualEdit == false,
            videoProcessing: data?.transcription !== null && data?.recaps?.recap1?.summary !== null && data?.recaps?.recap1?.narrationStoragePath !== null && data?.recaps?.recap1?.narratedVideoStoragePath === null,
          }
        }));
      });

      return () => unsub(); // Cleanup listener on unmount
    });
  }, [sessions]); // Dependencies array, useEffect runs when `sessions` changes


  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(user => {
      if (user) {
        // User is signed in

        // Fetch the campaign data from Firestore
        fetchCampaignData(campaignId).then(data => setCampaign(data));

        // Real-time update subscription for sessions
        const sessionCollection = collection(db, `users/${user.uid}/campaigns/${campaignId}/sessions`);
        const sessionQuery = query(sessionCollection, orderBy("timeCreated", "desc"));
        const unsubscribeSessions = onSnapshot(sessionQuery, (snapshot) => {
          const sessionData = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          setSessions(sessionData);
        });

        // Cleanup subscription on unmount or auth state change
        return () => {
          unsubscribeSessions();
        };
      } else {
        // User is not authenticated
        console.log('User not authenticated');
        setSessions([]); // Optionally clear sessions if the user is logged out
      }
    });

    // Clean up the auth listener when the component is unmounted
    return () => unsubscribeAuth();
  }, [campaignId]); // Dependency array, useEffect runs when `campaignId` changes



  const handleCreateSession = async (e) => {
    e.preventDefault();
    if (!sessionName) {
      alert("Please fill in all fields!");
      return;
    }

    try {

      modifyTokenBalance(auth.currentUser.uid, -1); // Deduct 1 token from the user's balance

      // Add to Firestore
      const newSession = {
        name: sessionName,
        sessionRecordingStoragePath: null,
        narrationStoragePath: null,
        transcription: null,
        summary: null,
        timeCreated: Timestamp.now()
      };
      const docRef = await addDoc(collection(db, `users/${auth.currentUser.uid}/campaigns/${campaignId}/sessions`), newSession);
      console.log("Document written with ID: ", docRef.id);

      // Handle Upload
      const storage_path = `users/${auth.currentUser.uid}/recordings/${selectedFile.name}`
      const storageRef = ref(storage, storage_path);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error(error);
        },
        async () => {



          // Update session document with the file URL
          await updateDoc(docRef, { 'sessionRecordingStoragePath': storage_path });

          // Clear the form
          setSessionName('');
          setSessionDescription('');
          setSessionImage('');
          setSelectedFile('');
          setProgress(0);

          // Update the local state with new session info
          newSession.sessionRecordingStoragePath = storage_path;
          newSession.id = docRef.id;
          setSessions([newSession, ...sessions]);
          console.log("Session ID: ", docRef.id)

          const endpoint = 'https://us-central1-dungeon-copilot-6065c.cloudfunctions.net/create_session';
          const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userID: auth.currentUser.uid,
              campaignID: campaignId,
              sessionID: docRef.id,
              recordingFileName: selectedFile.name,
              narrator: narratorDict[narrator],
              backgroundMusic: backgroundMusicDict[backgroundMusic]
            }),
          });

          const result = await response.json();
          if (response.ok) {
            console.log("Session created with ID:", result.sessionId);
          } else {
            console.error("Error in creating session: ", result.message);
            // Update UI based on error
          }
        }
      );
    } catch (error) {
      console.error("Error in creating session: ", error);
    }
  };


  return (
    <div class="max-w-7xl px-8 sm:px-12 lg:px-16 py-20 md:py-24 mx-auto">
      <div class="max-w-2xl mx-auto text-center mb-10 md:mb-14">
        <h1 class="text-3xl font-bold md:text-4xl md:leading-tight dark:text-white font-inknut">My Recaps</h1>
      </div>

      {sessions ? (
        <>

          {/* Sessions */}
          <div class="grid grid-cols-1 gap-4 max-w-4xl mx-auto">


            {sessions.map((session) => (
              <Link to={`/campaigns/${campaignId}/${session.id}`}>


                <div class="group flex py-4 px-6 items-center justify-between h-full rounded-md border border-gray-200 shadow-sm hover:bg-gray-50 dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">

                  {/* Name and Date */}
                  <div>
                    <h3 class="text-lg font-semibold text-gray-800 line-clamp-1 dark:text-gray-300 dark:hover:text-white font-cardo">
                      {session.name}
                    </h3>
                    <p class="text-gray-500 line-clamp-1 text-sm">
                      {moment(session.timeCreated.toDate()).format('MMMM Do, YYYY')}
                    </p>
                  </div>


                  {/* Right Side */}
                  <div className='flex flex-row gap-4 ml-4'>

                    {/* Status Spinner */}
                    {sessionsStatus[session.id]?.transcribing &&
                      <div class="flex items-center justify-center space-x-3">
                        <span class="text-xs whitespace-nowrap xs:block hidden">Transcribing</span>
                        <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-blue-800 rounded-full" role="status" aria-label="loading"></div>
                      </div>
                    }

                    {sessionsStatus[session.id]?.summarizing &&
                      <div class="flex items-center justify-center space-x-3">
                        <span class="text-xs whitespace-nowrap xs:block hidden">Summarizing</span>
                        <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-rose-500 rounded-full" role="status" aria-label="loading"></div>
                      </div>
                    }

                    {sessionsStatus[session.id]?.waitingForManualEdit &&
                      <div class="flex items-center justify-center space-x-2">
                        <span class="text-xs whitespace-nowrap xs:block hidden">Review recap</span>
                        <ScrybeLogo className='w-5 h-5' />
                      </div>
                    }

                    {sessionsStatus[session.id]?.narrating &&
                      <div class="flex items-center justify-center space-x-3">
                        <span class="text-xs whitespace-nowrap xs:block hidden">Narrating</span>
                        <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-yellow-500 rounded-full" role="status" aria-label="loading"></div>
                      </div>
                    }

                    {sessionsStatus[session.id]?.videoProcessing &&
                      <div class="flex items-center justify-center space-x-3">
                        <span class="text-xs whitespace-nowrap xs:block hidden whitespace-nowrap">Generating Video</span>
                        <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-green-500 rounded-full" role="status" aria-label="loading"></div>
                      </div>
                    }

                    {/* Kebab Menu */}
                    <div class="hs-dropdown relative inline-flex">
                      <button id="hs-dropdown-custom-icon-trigger" onClick={(e) => e.preventDefault()} type="button" class="z-0 hs-dropdown-toggle flex justify-center items-center w-9 h-9 text-sm font-semibold rounded-lg text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
                        <svg class="flex-none w-4 h-4 text-gray-600 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="1" /><circle cx="12" cy="5" r="1" /><circle cx="12" cy="19" r="1" /></svg>
                      </button>

                      <div onClick={(e) => { e.preventDefault(); setSelectedSession(session.name); setSelectedSessionId(session.id) }} class="hs-dropdown-menu z-10 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg border border-gray-200 p-2 mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700" aria-labelledby="hs-dropdown-custom-icon-trigger">
                        <button class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" data-hs-overlay="#hs-vertically-centered-modal">

                          {/* <IoCloseCircle className='w-4 h-4' /> */}
                          <IoTrashSharp className='w-4 h-4' />

                          Delete
                        </button>
                      </div>


                    </div>


                  </div>


                </div>

              </Link>
            ))}
          </div>




        </>
      ) : (
        <div class="max-w-4xl mx-auto">

          <div class="flex animate-pulse">

            <div class="ms-4 w-full space-y-4">
              <ul class="h-[82px] space-y-3 flex flex-col justify-center">
                <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>

              </ul>
              <ul class="h-[82px] space-y-3 flex flex-col justify-center">
                <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              </ul>
              <ul class="h-[82px] space-y-3 flex flex-col justify-center">
                <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                <li class="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              </ul>
            </div>
          </div>
        </div>

      )}



      <div id="hs-vertically-centered-modal" class="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
        <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
              <h3 class="font-bold text-gray-800 dark:text-white">
                Delete session?
              </h3>
              <button type="button" class="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700" data-hs-overlay="#hs-vertically-centered-modal">
                <span class="sr-only">Close</span>
                <svg class="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            {/* <div class="flex bg-gray-200 p-4 justify-center text-center font-bold m-4 rounded-lg"> */}
            <div class="flex py-4 px-6 m-6 text-lg font-semibold text-gray-800 items-center justify-center text-center h-full rounded-md border border-gray-200 shadow-sm font-cardo">

              {selectedSession}
            </div>
            <div class="p-6 pt-0 overflow-y-auto">
              <p class=" text-sm text-gray-800 dark:text-neutral-400 text-center">
                This will permanently remove the session along with all associated transcriptions, summaries, audio files, and videos.
              </p>
            </div>
            <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
              <button type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800" data-hs-overlay="#hs-vertically-centered-modal">
                Cancel
              </button>
              {/* Replace recaps with chosen campaign when ready */}
              <button type="button" onClick={() => deleteSession(auth.currentUser.uid, 'recaps', selectedSessionId)} class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black disabled:opacity-50 disabled:pointer-events-none" data-hs-overlay="#hs-vertically-centered-modal">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>



    </div>
  );
}

export default CampaignPage;
