import { doc, getDoc, updateDoc, deleteField, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from 'firebase/storage';


import { db, storage, auth } from "../firebaseConfig.js";

async function modifyTokenBalance(userID, amount) {

  // Call /modify-token-balance endpoint
  const endpoint = 'https://us-central1-dungeon-copilot-6065c.cloudfunctions.net/modify_token_balance';
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userID: userID,
      amount: amount
    }),
  });

  if (!response.ok) {
    // Extracting the full error message from the response
    const errorData = await response.json();
    alert(`You have no ink left. Please top up your ink supply to continue.`);
    console.error("Error response from the server:", errorData);
    throw new Error(`Server responded with error: ${response.status} ${response.statusText}`);
  }

  return await response;

}

// Delete session function
async function deleteSession(userID, campaignID, sessionID) {

  console.log(`Deleting session ${sessionID} from campaign ${campaignID} for user ${userID}.`);



  const sessionRef = doc(db, `users/${userID}/campaigns/${campaignID}/sessions/${sessionID}`);
  try {
    const docSnap = await getDoc(sessionRef);

    if (docSnap.exists()) {
      const recaps = docSnap.data().recaps;
      if (recaps) {
        Object.keys(recaps).forEach(recapKey => {
          const recap = recaps[recapKey];

          // Delete the narration
          if (recap.narrationStoragePath) {
            const narrationRef = ref(storage, recap.narrationStoragePath);
            deleteObject(narrationRef).then(() => {
              console.log(`Recap ${recapKey} Narration deleted successfully.`);
            }).catch((error) => {
              console.error(`Error deleting Recap ${recapKey} Narration:`, error);
            });
          }

          // Delete the narrated video
          if (recap.narratedVideoStoragePath) {
            const narratedVideoRef = ref(storage, recap.narratedVideoStoragePath);
            deleteObject(narratedVideoRef).then(() => {
              console.log(`Recap ${recapKey} Narrated Video deleted successfully.`);
            }).catch((error) => {
              console.error(`Error deleting Recap ${recapKey} Narrated Video:`, error);
            });
          }
          
          // Delete the recapref
          deleteDoc(sessionRef).then(() => {
            console.log(`Recap deleted successfully.`);
          }).catch((error) => {
            console.error(`Error deleting Recap:`, error);
          });

        });
      } else {
        console.log("No recaps found.");
      }
    } else {
      console.log("No such session found!");
    }
  } catch (error) {
    console.error("Error retrieving session:", error);
  }
}

export { modifyTokenBalance, deleteSession };
