
function TermsPage() {

    window.scrollTo(0, 0);
    
    return (
        <div>
            <div class="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
                <h1 class="mb-2 text-2xl font-bold md:text-4xl dark:text-white">Terms of Service</h1>
                <p class="text-gray-700 dark:text-gray-400">Last updated: January 13, 2024</p>
            </div>

            <div class="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
                <div class="grid gap-4 md:gap-8">
                    <div>
                        <h2 class="text-lg font-semibold mb-2 dark:text-white">Acceptance of Terms</h2>
                        <p class="mb-5 dark:text-gray-400">By accessing and using the website Scrybe (<a href="/"><strong>www.scrybe.ca</strong></a>), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you are not permitted to use Scrybe.</p>

                        <h2 class="text-lg font-semibold mb-2 dark:text-white">Service Description</h2>
                        <p class="mb-5 dark:text-gray-400">Scrybe is an online tool designed for tabletop role-playing game (TTRPG) players to upload recordings of their game sessions. Scrybe transcribes the audio, generates a recap using artificial intelligence, and provides a narration of the recap through text-to-speech (TTS) technology.</p>

                        <h2 class="text-lg font-semibold mb-2 dark:text-white">User Responsibilities</h2>

                        <ul class="list-disc pl-8 mb-5">
                            <li class="mb-2 dark:text-gray-400">You agree not to use Scrybe for any unlawful purposes or in a way that violates the rights of others.</li>
                        </ul>

                        <h2 class="text-lg font-semibold mb-2 dark:text-white">Intellectual Property Rights</h2>
                        <p class="mb-5 dark:text-gray-400">You are responsible for any content you upload to Scrybe, including obtaining necessary permissions for any copyrighted material. This includes but is not limited to recordings uploaded and custom uploaded background music.</p>
                        <p class="mb-5 dark:text-gray-400">If you publish your Scrybe-narrated recap elsewhere, you must attribute the original composer of the background music as detailed in the original composition's license. The required credit will be provided.</p>
                        <p class="mb-5 dark:text-gray-400">Aside from the provided background music, any content generated by Scrybe in response to your input or based on your uploaded recordings is automatically owned by you, the user. You are granted full copyright ownership, subject to the terms and conditions outlined in this agreement. This ownership includes the exclusive right to use, reproduce, modify, publish, distribute, perform, display, and create derivative works from the Generated Content, both within and outside of Scrybe, for any purpose.</p>
                        <p class="mb-5 dark:text-gray-400">Scrybe respects intellectual property rights and expects its users to do the same. Any infringement of intellectual property rights may result in the termination of your account.</p>

                        <h2 class="text-lg font-semibold mb-2 dark:text-white">Data Privacy</h2>
                        <p class="mb-5 dark:text-gray-400">Please refer to Scrybe's <a href="/privacy-policy"><strong>Privacy Policy</strong></a> for information on how personal data is collected, used, and shared.</p>

                        <h2 class="text-lg font-semibold mb-2 dark:text-white">Limitation of Liability</h2>
                        <p class="mb-5 dark:text-gray-400">Scrybe will not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the service.</p>

                        <h2 class="text-lg font-semibold mb-2 dark:text-white">Changes to the Terms</h2>
                        <p class="mb-5 dark:text-gray-400">Scrybe reserves the right to modify these Terms at any time. You are advised to review the Terms periodically for any changes.</p>

                        <h2 class="text-lg font-semibold mb-2 dark:text-white">Governing Law</h2>
                        <p class="mb-5 dark:text-gray-400">These Terms shall be governed by the laws of Canada. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the Canadian courts.</p>

                        <h2 class="text-lg font-semibold mb-2 dark:text-white"> Contact Information</h2>
                        <p class="mb-5 dark:text-gray-400">For any questions or concerns regarding these Terms, please contact us via the information provided on the <a href="/contact"><strong>Contact Page</strong>.</a></p>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsPage;