import { Link } from "react-router-dom";

function BreadCrumb({ breadcrumbs }) {

    return (
        <ol className="flex items-center whitespace-nowrap min-w-0" aria-label="Breadcrumb">
            {breadcrumbs.map((breadcrumb, index) => (
                <li key={index} className="text-sm">
                    {breadcrumb.link ? (
                        <Link to={breadcrumb.link}>
                            <a className={`flex items-center ${index === breadcrumbs.length - 1 ? 'font-semibold text-black truncate dark:text-gray-200' : 'text-gray-500 hover:text-gray-400'}`}>
                                {breadcrumb.name}
                                {index < breadcrumbs.length - 1 && (
                                    <svg className="flex-shrink-0 mx-3 overflow-visible h-2.5 w-2.5 text-gray-400 dark:text-gray-600" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                )}
                            </a>
                        </Link>
                    ) : (
                        <span className="font-semibold text-black truncate dark:text-gray-200" aria-current="page">
                            {breadcrumb.name}
                        </span>
                    )}
                </li>
            ))}
        </ol>
    );
}

export default BreadCrumb;
