
import { Menu, Transition } from '@headlessui/react'

import { useParams, useNavigate } from 'react-router-dom';
import { auth, storage, db } from '../firebaseConfig';
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { updateDoc, collection, addDoc, Timestamp } from 'firebase/firestore';
import Select, { components } from 'react-select';
import { FaPlay, FaStop, FaUpload } from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";
import { FiUpload } from "react-icons/fi";



import { modifyTokenBalance } from '../utils/FirebaseFunctionsHelper';

import UploadField from './UploadField';

// Narrator Dictionary
const narratorDict = {
    Josha: "nyeXGhluSezAnVjsQsbQ",
    Ixel: "Z77SbP4Tp0Wb71ywQ35v",
    Victoria: "fnHrJf6lxn8qpNfdSuAu",
    // Oliver: "PgMyeAGf9RWm0c1wRJMB",
    // Ella: "5yz1y2zDf5iCaWq5YSqP",
    Joanne: "j1xLWTLKxEl0e6WENGs0",
    Cecile: "bvz5eNAMkfKPt7uTLym7",
    Jami: "lUE3vcpcTVGfJ7KVgjbA",
    Oswald: "UMkQssU5lwO9uLwTkGIh",
    // Paddington: "Z3jmT340rOZGuYHJ1FSV",
    James: "zpnRoleXRhWcv8KmQc0N",
    // Jake: "IswAQI4G0OV6rDnnL5CZ",
    Robert: "2HEvybW5JPcDQ4g23KG5",
    // Ali: "E7ibhdx5VVTpgExHvgtA",
    Asha: "o3DDJnC41aNSrsgfkrXJ",
    // Mark: "A79au8IlWn9TxNWXPvg9",
    // Jessie: "t0jbNlBVZ17f02VDIeMI",
    Lee: "abRFZIdN4pvo8ZPmGxHP",
    // Dakota: "P7x743VjyZEOihNNygQ9",
    Andromeda: "Xw54iFVGvOkBBF7zOf0Z",
    // Kelcey: "076yr2YVmmwwot5RRvZz",
    // Andy: "kVBPcEMsUF1nsAO1oNWw",
    Carter: "qNkzaJoHLLdpvgh5tISm",
    // Arthur: "8ZBQD0m1R6EIchgSltwB",
    Daria: "DUhzmIGFwXJ752SvgcCj",
    Ronan: "V4aTMuwwYUtBD7ZqVvZs",
    Von: "Mg1264PmwVoIedxsF9nu",
    // Wickey: "TJ5lH6wsCvZ2alDg7xrH",
    // Tony: "2ovNLFOsfyKPEWV5kqQi",
    Silas: "KTPVrSVAEUSJRClDzBw7",
    Eleanor: "VzyQ6qo0GJY1pcTvoV5g",
    Fizzlebert: "f2yUVfK5jdm78zlpcZ8C",
    // Morganna: "7NsaqHdLuKNFvEfjpUno",
    // Domus: "hw6C9hFncBrhy8iM73f2",
    Claria: "1vHrrFQuLuyqEl17e9gl",
    // David: "BNgbHR0DNeZixGQVzloa", // TOO LOUD and a bit bad quality. Bring back when I fixe narration audio normalization
    Nasim: "tlETan7Okc4pzjD0z62P",
    // Jean: "4p5WXd3ZuWR9pPtRQuxC",
    Alfie : "v9I7auPeR1xGKYRPwQGG",
    Mira: "sVSfqK2DvxZjFxL8fd8g",
    George : "geeIXR6sVlEJDekIvnOL",
    // Julian : "7p1Ofvcwsv7UBPoFNcpI",


};


const UploadSession = ({ currUser }) => {


    const [sessions, setSessions] = useState([]);
    const [sessionName, setSessionName] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedCustomMusicFile, setSelectedCustomMusicFile] = useState(null);

    const [progress, setProgress] = useState(0);
    const [customMusicUploadProgress, setCustomMusicUploadProgress] = useState(0);

    const [narrator, setNarrator] = useState('Ixel');
    const [narratorModel, setNarratorModel] = useState('eleven_monolingual_v1');

    const [backgroundMusicName, setBackgroundMusicName] = useState('Expedition Planning');
    const [backgroundMusic, setBackgroundMusic] = useState('expedition_planning');
    const [showCustomMusicInput, setShowCustomMusicInput] = useState(false);

    const [currentPlaying, setCurrentPlaying] = useState({ audio: null, id: null });

    const [uploading, setUploading] = useState(false);

    const closeMenuRef = useRef(null);

    const [buttonText, setButtonText] = useState('Submit');

    const [isCheckedManualEdit, setIsCheckedManualEdit] = useState(false);

    let navigate = useNavigate();

    function getBackgroundMusicPath(backgroundMusicName) {

        if (backgroundMusicName === "custom") {
            return `users/${auth.currentUser.uid}/custom_music/${selectedCustomMusicFile.name}`;
        } else {
            return `background_music/background_music_${backgroundMusicName.toLowerCase().replace(' ', '_')}.mp3`;

        }
    }

    function handleCustomMusicUpload(docRef) {
        const storage_path = `users/${auth.currentUser.uid}/custom_music/${selectedCustomMusicFile.name}`
        const storageRef = ref(storage, storage_path);
        const uploadTask = uploadBytesResumable(storageRef, selectedCustomMusicFile);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setCustomMusicUploadProgress(progress);
            },
            (error) => {
                console.error(error);
                setUploading(false);
                setButtonText('Submit');
            },
            async () => {
                setUploading(false);
                setButtonText('Submit');

                // Update session document with the file URL
                await updateDoc(docRef, { 'customMusicStoragePath': storage_path });

            }
        );
    }

    const handleCreateSession = async (e) => {

        e.preventDefault();

        if (!currUser) {
            alert("Please sign up or log in to generate recaps.");
            return;
        }

        // Prevent duplicate submissions
        if (uploading) return;


        // Make sure form is filled out
        if (!sessionName) {
            alert('Please enter a recap name');
            return;
        }
        if (!selectedFile) {
            alert('Please select a session recording to upload');
            return;
        }

        try {
            setButtonText('Dipping Quill');
            setUploading(true);
            await modifyTokenBalance(auth.currentUser.uid, -1); // Deduct 1 token from the user's balance
            setButtonText('Uploading');

            // Add to Firestore
            const newSession = {
                name: sessionName,
                sessionRecordingStoragePath: null,
                transcription: null,
                timeCreated: Timestamp.now(),

                recaps: {
                    recap1: {
                        summary: null,
                        narrationStoragePath: null,
                        narrator: {
                            name: narrator,
                            id: narratorDict[narrator],
                            model: narratorModel
                        },
                        backgroundMusicPath: getBackgroundMusicPath(backgroundMusic),
                        backgroundMusicName: backgroundMusicName,
                        narratedVideoStoragePath: null,
                        manualEdit: isCheckedManualEdit
                    }
                }

            };

            const docRef = await addDoc(collection(db, `users/${auth.currentUser.uid}/campaigns/recaps/sessions`), newSession);

            // If custom music file is selected, upload it
            if (selectedCustomMusicFile) {
                handleCustomMusicUpload(docRef);
            }

            // Handle Upload
            const storage_path = `users/${auth.currentUser.uid}/recordings/${selectedFile.name}`
            const storageRef = ref(storage, storage_path);
            const uploadTask = uploadBytesResumable(storageRef, selectedFile);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(progress);
                },
                (error) => {
                    console.error(error);
                    setUploading(false);
                    setButtonText('Submit');
                },
                async () => {

                    setUploading(false);
                    setButtonText('Submit');

                    // navigate to the new session page
                    if (closeMenuRef.current) {
                        closeMenuRef.current();
                    }

                    navigate('/campaigns/recaps');

                    // Update session document with the file URL
                    await updateDoc(docRef, { 'sessionRecordingStoragePath': storage_path });

                    // Clear the form
                    setSessionName('');
                    setSelectedFile(null);
                    setSelectedCustomMusicFile(null);
                    setShowCustomMusicInput(false);

                    setProgress(0);
                    setCustomMusicUploadProgress(0);

                    // Update the local state with new session info
                    newSession.sessionRecordingStoragePath = storage_path;
                    newSession.id = docRef.id;
                    setSessions([newSession, ...sessions]);
                    console.log("Session ID: ", docRef.id)

                    const endpoint = 'https://us-central1-dungeon-copilot-6065c.cloudfunctions.net/create_session';
                    const response = await fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            userID: auth.currentUser.uid,
                            campaignID: "recaps",
                            sessionID: docRef.id,
                            recordingFileName: selectedFile.name,
                            narrator: narratorDict[narrator],
                            narratorModel: narratorModel,
                            backgroundMusic: getBackgroundMusicPath(backgroundMusic),
                            manualEdit: isCheckedManualEdit
                        }),
                    });

                    const result = await response.json();
                    if (response.ok) {
                        console.log("Session created with ID:", result.sessionId);
                    } else {
                        console.error("Error in creating session: ", result.message);
                        // Update UI based on error
                    }
                }
            );
        } catch (error) {

            setUploading(false);
            setButtonText('Submit');
            console.error("Error in creating session: ", error);


        }
    };

    const suppressSpaceKey = (e) => {
        if (e.key === ' ') {
            e.stopPropagation();
        }
    };

    const handleMusicSelection = (selectedMusic) => {
        setBackgroundMusic(selectedMusic);

        // Check if the selected option is 'Custom'
        if (selectedMusic === "custom") {
            setShowCustomMusicInput(true); // Show the custom input field
        } else {
            setShowCustomMusicInput(false); // Hide the custom input field
        }
    }

    const playSong = async (songPath, id) => {
        if (currentPlaying.audio && currentPlaying.id !== id) {
            currentPlaying.audio.pause();
            currentPlaying.audio.currentTime = 0;
        }

        try {
            const songRef = ref(storage, songPath);
            const url = await getDownloadURL(songRef);
            // console.log("URL: ", url);
            const newAudio = new Audio(url);
            newAudio.volume = 0.5;



            setCurrentPlaying({ audio: newAudio, id: id });

            // Await the play promise to handle it properly
            // console.log("Trying to play audio")
            newAudio.addEventListener('loadeddata', () => {
                newAudio.play();
            });
            // console.log("Audio played")
            // Cleanup when audio finishes
            newAudio.onended = () => {
                setCurrentPlaying({ audio: null, id: null });
            };
        } catch (error) {
            console.error('Error playing audio:', error);
            // Handle the error, possibly by retrying the play command or logging the error
        }

    };


    const stopSong = () => {
        if (currentPlaying.audio) {
            currentPlaying.audio.pause();
            currentPlaying.audio.currentTime = 0;
            setCurrentPlaying({ audio: null, id: null });
        }
    };

    const narratorOptions = [
        { value: 'Andromeda', label: 'Andromeda', songPath: 'narrator_samples/narrator_sample_andromeda.mp3', voice_type: 'feminine', tags: ['British', 'Confident'], model: 'eleven_turbo_v2' },
        // { value: 'Ali', label: 'Ali', songPath: 'narrator_samples/narrator_sample_ali.mp3', voice_type: 'masculine', tags: ['American', 'Young', 'Conversational'], model: 'eleven_multilingual_v2' },
        { value: 'Asha', label: 'Asha', songPath: 'narrator_samples/narrator_sample_asha.mp3', voice_type: 'feminine', tags: ['African', 'Eloquent'], model: 'eleven_turbo_v2' },
        { value: 'Cecile', label: 'Cecile', songPath: 'narrator_samples/narrator_sample_cecile.mp3', voice_type: 'feminine', tags: ['British', 'Mature', 'Grandma'], model: 'eleven_monolingual_v1' },
        // { value: 'Dakota', label: 'Dakota', songPath: 'narrator_samples/narrator_sample_dakota.mp3', voice_type: 'feminine', tags: ['American', '', ''], model: 'eleven_turbo_v2' },
        // { value: 'Ella', label: 'Ella', songPath: 'narrator_samples/narrator_sample_ella.mp3', voice_type: 'feminine', tags: ['British', 'Young', 'Sweet'], model: 'eleven_monolingual_v1' },
        { value: 'Ixel', label: 'Ixel', songPath: 'narrator_samples/narrator_sample_ixel.mp3', voice_type: 'masculine', tags: ['British', 'Deep', 'Sophisticated'], model: 'eleven_monolingual_v1' },
        // { value: 'Jami', label: 'Jami', songPath: 'narrator_samples/narrator_sample_jami.mp3', voice_type: 'masculine', tags: ['African', 'Calm', 'Confidant'], model: 'eleven_multilingual_v2' },
        { value: 'James', label: 'James', songPath: 'narrator_samples/narrator_sample_james.mp3', voice_type: 'masculine', tags: ['Irish', 'Expressive'], model: 'eleven_multilingual_v1' },
        // { value: 'Jake', label: 'Jake', songPath: 'narrator_samples/narrator_sample_jake.mp3', voice_type: 'masculine', tags: ['American', 'Deep', 'Commander'], model: 'eleven_multilingual_v2' },
        // { value: 'Jessie', label: 'Jessie', songPath: 'narrator_samples/narrator_sample_jessie.mp3', voice_type: 'masculine', tags: ['American', 'Southern', 'Cowboy'], model: 'eleven_multilingual_v2' },
        { value: 'Joanne', label: 'Joanne', songPath: 'narrator_samples/narrator_sample_joanne.mp3', voice_type: 'feminine', tags: ['American', 'Young', 'Soft'], model: 'eleven_monolingual_v1' },
        { value: 'Josha', label: 'Josha', songPath: 'narrator_samples/narrator_sample_josha.mp3', voice_type: 'masculine', tags: ['American', 'Deep'], model: 'eleven_monolingual_v1' },
        // { value: 'Kelcey', label: 'Kelcey', songPath: 'narrator_samples/narrator_sample_kelcey.mp3', voice_type: 'feminine', tags: ['American', 'Young', 'Upbeat'], model: 'eleven_turbo_v2' },
        // { value: 'Lee', label: 'Lee', songPath: 'narrator_samples/narrator_sample_lee.mp3', voice_type: 'masculine', tags: ['Australian', 'Deep', 'Friendly'], model: 'eleven_turbo_v2' },
        // { value: 'Mark', label: 'Mark', songPath: 'narrator_samples/narrator_sample_mark.mp3', voice_type: 'masculine', tags: ['Canadian', 'Conversational'], model: 'eleven_multilingual_v2' },
        // { value: 'Oliver', label: 'Oliver', songPath: 'narrator_samples/narrator_sample_oliver.mp3', voice_type: 'masculine', tags: ['British', 'Young', 'Calm'], model: 'eleven_monolingual_v1' },
        { value: 'Oswald', label: 'Oswald', songPath: 'narrator_samples/narrator_sample_oswald.mp3', voice_type: 'masculine', tags: ['British', 'Intelligent', 'Professor'], model: 'eleven_monolingual_v1' },
        // { value: 'Paddington', label: 'Paddington', songPath: 'narrator_samples/narrator_sample_paddington.mp3', voice_type: 'masculine', tags: ['', '', ''], model: 'eleven_multilingual_v2' },
        { value: 'Robert', label: 'Robert', songPath: 'narrator_samples/narrator_sample_robert.mp3', voice_type: 'masculine', tags: ['British', 'Transatlantic', 'Old-Timey'], model: 'eleven_monolingual_v1' },
        { value: 'Victoria', label: 'Victoria', songPath: 'narrator_samples/narrator_sample_victoria.mp3', voice_type: 'feminine', tags: ['British', 'Mature', 'Classy'], model: 'eleven_turbo_v2' },

        // { value: 'Andy', label: 'Andy', songPath: 'narrator_samples/narrator_sample_andy.mp3', voice_type: 'masculine', tags: ['British', 'Intense', 'Aggressive'], model: 'eleven_turbo_v2' }, // Too quiet
        { value: 'Carter', label: 'Carter', songPath: 'narrator_samples/narrator_sample_carter.mp3', voice_type: 'masculine', tags: ['American', 'Deep', 'Commander'], model: 'eleven_turbo_v2' },
        // { value: 'Arthur', label: 'Arthur', songPath: 'narrator_samples/narrator_sample_arthur.mp3', voice_type: 'masculine', tags: ['British', 'Wise', 'Wizard'], model: 'eleven_turbo_v2' },
        { value: 'Daria', label: 'Daria', songPath: 'narrator_samples/narrator_sample_daria.mp3', voice_type: 'feminine', tags: ['Fey', 'Quirky', 'Energetic'], model: 'eleven_turbo_v2' },
        { value: 'Ronan', label: 'Ronan', songPath: 'narrator_samples/narrator_sample_ronan.mp3', voice_type: 'masculine', tags: ['Scottish', 'Serious', 'Gruff'], model: 'eleven_turbo_v2' },
        { value: 'Von', label: 'Von', songPath: 'narrator_samples/narrator_sample_von.mp3', voice_type: 'masculine', tags: ['American', 'Eccentric', 'Mad Scientist'], model: 'eleven_turbo_v2' },
        // { value: 'Wickey', label: 'Wickey', songPath: 'narrator_samples/narrator_sample_wickey.mp3', voice_type: 'masculine', tags: ['American', 'Calm', 'Happy'], model: 'eleven_turbo_v2' },
        // { value: 'Tony', label: 'Tony', songPath: 'narrator_samples/narrator_sample_tony.mp3', voice_type: 'masculine', tags: ['American', 'Mafia', 'New Yorker'], model: 'eleven_turbo_v2' },
        { value: 'Silas', label: 'Silas', songPath: 'narrator_samples/narrator_sample_silas.mp3', voice_type: 'masculine', tags: ['American', 'Southern', 'Cowboy'], model: 'eleven_turbo_v2' },
        { value: 'Eleanor', label: 'Eleanor', songPath: 'narrator_samples/narrator_sample_eleanor.mp3', voice_type: 'feminine', tags: ['British', 'Mature', 'Serious'], model: 'eleven_multilingual_v2' },
        { value: 'Fizzlebert', label: 'Fizzlebert', songPath: 'narrator_samples/narrator_sample_fizzlebert.mp3', voice_type: 'masculine', tags: ['Gnomish', 'Mischievous', 'Trickster'], model: 'eleven_multilingual_v2' },
        // { value: 'Domus', label: 'Domus', songPath: 'narrator_samples/narrator_sample_domus.mp3', voice_type: 'masculine', tags: ['American', 'Deep', 'Serious'], model: 'eleven_turbo_v2' },
        { value: 'Claria', label: 'Claria', songPath: 'narrator_samples/narrator_sample_claria.mp3', voice_type: 'feminine', tags: ['British', 'Formal'], model: 'eleven_turbo_v2' },
        // { value: 'Morganna', label: 'Morganna', songPath: 'narrator_samples/narrator_sample_morganna.mp3', voice_type: 'feminine', tags: ['Mature', 'Witch', 'Cruel'], model: 'eleven_turbo_v2'}
        // { value: 'David', label: 'David', songPath: 'narrator_samples/narrator_sample_david.mp3', voice_type: 'masculine', tags: ['British', 'Storyteller'], model: 'eleven_turbo_v2' }, // TOO LOUD and a bit bad quality. Bring back when I fixe narration audio normalization
        { value: 'Nasim', label: 'Nasim', songPath: 'narrator_samples/narrator_sample_nasim.mp3', voice_type: 'masculine', tags: ['Arabic', 'Mysterious'], model: 'eleven_turbo_v2' },
        // { value: 'Jean', label: 'Jean', songPath: 'narrator_samples/narrator_sample_jean.mp3', voice_type: 'masculine', tags: ['French-American', 'Calm'], model: 'eleven_multilingual_v2' },
        { value: 'Alfie', label: 'Alfie', songPath: 'narrator_samples/narrator_sample_alfie.mp3', voice_type: 'masculine', tags: ['Northern English', 'Narrative'], model: 'eleven_turbo_v2' }, //aka Tony
        { value: 'Mira', label: 'Mira', songPath: 'narrator_samples/narrator_sample_mira.mp3', voice_type: 'feminine', tags: ['British', 'Bold', 'Confident'], model: 'eleven_english_v2' },
        { value: 'George', label: 'George', songPath: 'narrator_samples/narrator_sample_george.mp3', voice_type: 'masculine', tags: ['American', 'Deep', 'Commander'], model: 'eleven_multilingual_v2' },
        // { value: 'Julian', label: 'Julian', songPath: 'narrator_samples/narrator_sample_julian.mp3', voice_type: 'masculine', tags: ['British', 'Rich Tone'], model: 'eleven_english_v2' }, //Too quiet

    ];

    // David: "BNgbHR0DNeZixGQVzloa",
    // Nasim: "tlETan7Okc4pzjD0z62P",
    // Jean: "4p5WXd3ZuWR9pPtRQuxC",
    // Alfie : "v9I7auPeR1xGKYRPwQGG",

    // Sort narrator options alphabetically
    narratorOptions.sort((a, b) => a.label.localeCompare(b.label));

    const groupedOptionsVoices = [
        {
            label: "Masculine",
            options: narratorOptions.filter(option => option.voice_type === 'masculine')
        },
        {
            label: "Feminine",
            options: narratorOptions.filter(option => option.voice_type === 'feminine')
        }
    ];

    const groupedOptionsMusic = [
        {
            options: [
                { value: 'custom', label: 'Custom' }
            ]
        },
        {
            label: "Calm",
            options: [
                { value: 'adventure', label: 'Adventure', songPath: 'background_music/background_music_adventure.mp3' },
                { value: 'azure_purple_gold', label: 'Azure Purple Gold', songPath: 'background_music/background_music_azure_purple_gold.mp3' },
                { value: 'basilica_of_the_heavens', label: 'Basilica of the Heavens', songPath: 'background_music/background_music_basilica_of_the_heavens.mp3' },
                { value: 'beautiful_village', label: 'Beautiful Village', songPath: 'background_music/background_music_beautiful_village.mp3' },
                { value: 'casting_off', label: 'Casting Off', songPath: 'background_music/background_music_casting_off.mp3' },
                { value: 'dreams_of_an_alpine_spring', label: 'Dreams of an Alpine Spring', songPath: 'background_music/background_music_dreams_of_an_alpine_spring.mp3' },
                { value: 'expedition_planning', label: 'Expedition Planning', songPath: 'background_music/background_music_expedition_planning.mp3' },
                { value: 'geof_the_blacksmith', label: 'Geof the Blacksmith', songPath: 'background_music/background_music_geof_the_blacksmith.mp3' },
                { value: 'llanfair', label: 'Llanfair', songPath: 'background_music/background_music_llanfair.mp3' },
                { value: 'magic_tavern', label: 'Magic Tavern', songPath: 'background_music/background_music_magic_tavern.mp3' },
                { value: 'magical_forest', label: 'Magical Forest', songPath: 'background_music/background_music_magical_forest.mp3' },
                { value: 'minstrels_song', label: 'Minstrel\'s Song', songPath: 'background_music/background_music_minstrels_song.mp3' },
                { value: 'mystical', label: 'Mystical', songPath: 'background_music/background_music_mystical.mp3' },
                { value: 'night_in_a_frozen_forest', label: 'Night in a Frozen Forest', songPath: 'background_music/background_music_night_in_a_frozen_forest.mp3' },
                { value: 'old_creek_grove', label: 'Old Creek Grove', songPath: 'background_music/background_music_old_creek_grove.mp3' },
                { value: 'quaint_quest', label: 'Quaint Quest', songPath: 'background_music/background_music_quaint_quest.mp3' },
                { value: 'ring_of_iron', label: 'Ring of Iron', songPath: 'background_music/background_music_ring_of_iron.mp3' },
                { value: 'royal_market', label: 'Royal Market', songPath: 'background_music/background_music_royal_market.mp3' },
                { value: 'spirits_refuge', label: 'Spirits Refuge', songPath: 'background_music/background_music_spirits_refuge.mp3' },
                { value: 'the_herbalist', label: 'The Herbalist', songPath: 'background_music/background_music_the_herbalist.mp3' },
                { value: 'the_white_city', label: 'The White City', songPath: 'background_music/background_music_the_white_city.mp3' },
                { value: 'tiny_kingdom', label: 'Tiny Kingdom', songPath: 'background_music/background_music_tiny_kingdom.mp3' },
                { value: 'winterlight', label: 'Winterlight', songPath: 'background_music/background_music_winterlight.mp3' },
                { value: 'wode', label: 'Wode', songPath: 'background_music/background_music_wode.mp3' },
                { value: 'world_asleep', label: 'World Asleep', songPath: 'background_music/background_music_world_asleep.mp3' },
                { value: 'you_are_going_home', label: 'Going Home', songPath: 'background_music/background_music_you_are_going_home.mp3' },

            ]
        },
        {
            label: "Epic",
            options: [
                { value: 'dwarven_kings_tomb', label: 'Dwarven King\'s Tomb', songPath: 'background_music/background_music_dwarven_kings_tomb.mp3' },
                { value: 'protecting_neverwinter', label: 'Protecting Neverwinter', songPath: 'background_music/background_music_protecting_neverwinter.mp3' },
                { value: 'pulse_of_the_unknown', label: 'Pulse of the Unknown', songPath: 'background_music/background_music_pulse_of_the_unknown.mp3' },
                { value: 'the_city_of_akaton', label: 'The City of Akaton', songPath: 'background_music/background_music_the_city_of_akaton.mp3' },
            ]
        },
        {
            label: "Mysterious",
            options: [
                { value: 'cursed_island', label: 'Cursed Island', songPath: 'background_music/background_music_cursed_island.mp3' },
                { value: 'first_light', label: 'First Light', songPath: 'background_music/background_music_first_light.mp3' },
                { value: 'jewel_of_nekhen', label: 'Jewel of Nekhen', songPath: 'background_music/background_music_jewel_of_nekhen.mp3' },
                { value: 'link_street', label: 'Link Street', songPath: 'background_music/background_music_link_street.mp3' },
                { value: 'queen_of_the_dead', label: 'Queen of the Dead', songPath: 'background_music/background_music_queen_of_the_dead.mp3' },
                { value: 'the_dragon_hoard', label: 'The Dragon Hoard', songPath: 'background_music/background_music_the_dragon_hoard.mp3' },
                { value: 'the_ruins_of_atlantis', label: 'The Ruins of Atlantis', songPath: 'background_music/background_music_the_ruins_of_atlantis.mp3' },
                { value: 'the_legend_of_narmer', label: 'The Legend of Narmer', songPath: 'background_music/background_music_the_legend_of_narmer.mp3' },
                { value: 'victorian', label: 'Victorian Mystery', songPath: 'background_music/background_music_victorian.mp3' },
            ]
        },
        {
            label: "Tense",
            options: [
                { value: 'blood_magic', label: 'Blood Magic', songPath: 'background_music/background_music_blood_magic.mp3' },
                { value: 'coven', label: 'Coven', songPath: 'background_music/background_music_coven.mp3' },
                { value: 'distant_sun', label: 'Distant Sun', songPath: 'background_music/background_music_distant_sun.mp3' },
                { value: 'harsh_judgement', label: 'Harsh Judgement', songPath: 'background_music/background_music_harsh_judgement.mp3' },
                { value: 'the_long_path', label: 'The Long Path', songPath: 'background_music/background_music_the_long_path.mp3' },
                { value: 'uncertain_tidings', label: 'Uncertain Tidings', songPath: 'background_music/background_music_uncertain_tidings.mp3' },
                { value: 'whispering_door', label: 'Whispering Door', songPath: 'background_music/background_music_whispering_door.mp3' },
                { value: 'without_god', label: 'Without God', songPath: 'background_music/background_music_without_god.mp3' },
            ]
        },
        {
            label: "Miscellaneous",
            options: [
                { value: 'celebration', label: 'Celebration', songPath: 'background_music/background_music_celebration.mp3' },
                { value: 'cesare_rides_again', label: 'Cesare Rides Again', songPath: 'background_music/background_music_cesare_rides_again.mp3' },
                { value: 'the_governors_minuet', label: 'The Governor\'s Minuet', songPath: 'background_music/background_music_the_governors_minuet.mp3' },

            ]
        },

    ];

    const formatGroupLabel = data => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{data.label}</span>
            <span style={{
                backgroundColor: '#EBECF0',
                borderRadius: '2em',
                color: '#172B4D',
                display: 'inline-block',
                fontSize: 12,
                lineHeight: '1',
                minWidth: 1,
                padding: '0.16666666666667em 0.5em',
                textAlign: 'center',
            }}>
                {data.options.length}
            </span>
        </div>
    );

    const CustomOption = ({ data, isPlaying, ...props }) => {
        const [loading, setLoading] = useState(false);

        const togglePlay = async (e) => {
            e.stopPropagation();

            if (isPlaying) {
                // set isPlaying to false
                isPlaying = false;
                stopSong();
            } else {
                setLoading(true);
                await playSong(data.songPath, data.value);
                setLoading(false);
            }
        };

        return (
            <components.Option {...props}>
                <div className="flex items-center gap-3 ">

                    {/* Play/Stop button */}
                    {data.songPath && (
                        <button
                            type="button"
                            className="background-none border-none"
                            onClick={togglePlay}
                        >
                            {loading ? (
                                <div
                                    className="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent rounded-full"
                                    role="status"
                                    aria-label="loading"
                                />
                            ) : (
                                isPlaying ? <FaStop className='w-4 h-4 hover:opacity-50' /> : <FaPlay className='w-4 h-4 hover:opacity-50' />
                            )}
                        </button>
                    )}

                    {/* Name */}
                    {data.value === 'custom' && (
                        <FiUpload className='w-4 h-4' />
                    )}

                    {data.label}


                    {/* Tags */}
                    <div className="flex gap-1 ml-2 overflow-auto">
                        {data.tags && data.tags.map((tag, index) => (
                            <span key={index} className="inline-block px-2 py-1 text-xs font-semibold border border-gray-500 whitespace-nowrap rounded-full dark:bg-gray-700 dark:text-gray-400">
                                {tag}
                            </span>
                        ))}
                    </div>
                </div>
            </components.Option>
        );
    };


    const stopAudioOnClose = () => {
        if (currentPlaying.audio) {
            stopSong(); // This is the function you already have
        }
    };



    return (
        <div>


            <Menu as="div" className="relative inline-block text-left">

                {({ close }) => (
                    <>
                        {closeMenuRef.current = close}
                        <div>
                            <Menu.Button class="py-2 px-3 sm:py-3 sm:px-4 inline-flex items-center whitespace-nowrap gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                + New Recap
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            afterLeave={stopAudioOnClose} // Stop audio when menu closes
                        >

                            <Menu.Items className="absolute z-10 mt-2 origin-top left-1/2 -translate-x-1/2 min-w-[15rem] w-[80vw] max-w-[50rem] border bg-white shadow-md rounded-xl">



                                <form onSubmit={handleCreateSession}>

                                    <div class="p-4 sm:p-7">
                                        <div class="space-y-4 sm:space-y-6">

                                            <div class="space-y-2">
                                                <label for="af-submit-app-recap-name" class="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                                                    Recap name
                                                </label>

                                                <input
                                                    id="af-submit-app-recap-name"
                                                    type="text"
                                                    placeholder="Enter recap name"
                                                    value={sessionName}
                                                    onChange={(e) => setSessionName(e.target.value)}
                                                    onKeyDown={suppressSpaceKey}
                                                    class="border py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                                                />

                                            </div>

                                            <div className="space-y-2 ">
                                                <div className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                                                    Narrator
                                                </div>
                                                <Select
                                                    // Default value is narratorOptions where value is 'Ixel'
                                                    defaultValue={narratorOptions[narratorOptions.findIndex(option => option.value === 'Ixel')]}
                                                    options={groupedOptionsVoices}
                                                    // formatGroupLabel={formatGroupLabel}
                                                    components={{
                                                        Option: (props) => <CustomOption
                                                            {...props}
                                                            isPlaying={currentPlaying.id === props.data.value}
                                                        />
                                                    }}

                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            borderColor: 'rgb(229, 231, 235)',
                                                            borderRadius: '8px',
                                                        }),
                                                        option: (styles) => ({
                                                            ...styles,
                                                            cursor: 'pointer'
                                                        })
                                                    }}


                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'rgb(229, 231, 235)',
                                                            primary50: 'rgb(229, 231, 235)',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                    onChange={(e) => {
                                                        stopSong();
                                                        setNarrator(e.label)
                                                        setNarratorModel(e.model)
                                                    }
                                                    }
                                                    onMenuClose={stopSong}
                                                    isSearchable={false}
                                                />
                                            </div>

                                            <div className="space-y-2">
                                                <div className="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                                                    Background music
                                                </div>
                                                <Select
                                                    defaultValue={groupedOptionsMusic.find(group => group.label === "Calm").options.find(option => option.value === "expedition_planning")}
                                                    options={groupedOptionsMusic}
                                                    // formatGroupLabel={formatGroupLabel}
                                                    components={{
                                                        Option: (props) => <CustomOption
                                                            {...props}
                                                            isPlaying={currentPlaying.id === props.data.value}
                                                        />
                                                    }}
                                                    styles={{
                                                        control: (base) => ({
                                                            ...base,
                                                            borderColor: 'rgb(229, 231, 235)',
                                                            borderRadius: '8px',
                                                        }),
                                                        option: (styles) => ({
                                                            ...styles,
                                                            cursor: 'pointer'
                                                        })
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'rgb(229, 231, 235)',
                                                            primary50: 'rgb(229, 231, 235)',
                                                            primary: 'black',
                                                        },
                                                    })}
                                                    onChange={(e) => {
                                                        stopSong();
                                                        setBackgroundMusicName(e.label)
                                                        handleMusicSelection(e.value);
                                                    }
                                                    }
                                                    onMenuClose={stopSong}
                                                    isSearchable={false}
                                                />
                                            </div>

                                            {showCustomMusicInput && (

                                                <div class="space-y-2">
                                                    <label for="af-custom-music" class="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                                                        Upload Custom Background Music
                                                    </label>

                                                    <UploadField
                                                        supportedFileTypes={['mp3']}
                                                        maxFileSize={50}
                                                        onFileSelect={(selectedFile) => {
                                                            setSelectedCustomMusicFile(selectedFile);
                                                        }} />

                                                    <div class="flex w-full mb-1 h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700">
                                                        <div class="flex flex-col justify-center overflow-hidden bg-gray-500 transition-width duration-300 ease-in-out"
                                                            role="progressbar"
                                                            style={{ width: `${customMusicUploadProgress}%` }}
                                                            aria-valuenow={customMusicUploadProgress}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100">
                                                        </div>
                                                    </div>
                                                </div>


                                            )}

                                            <div class="space-y-2">
                                                <label for="af-submit-app-upload-session" class="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                                                    Session recording
                                                </label>

                                                <UploadField
                                                    supportedFileTypes={['mp3', 'wav', 'mpeg', 'm4a']}
                                                    maxFileSize={500}
                                                    onFileSelect={(selectedFile) => {
                                                        setSelectedFile(selectedFile);
                                                    }} />

                                                {/* <label for="af-submit-app-upload-session" class="group p-4 sm:p-7 block cursor-pointer text-center border-2 border-dashed border-gray-200 rounded-lg focus-within:outline-none focus-within:ring-2 focus-within:ring-gray-500 focus-within:ring-offset-2 dark:border-gray-700">
                                                    <input id="af-submit-app-upload-session" name="af-submit-app-upload-session" type="file" class="sr-only"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            console.log(file);
                                                            setSelectedFile(file);
                                                        }}
                                                    />
                                                    <svg class="w-10 h-10 mx-auto text-gray-400 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                                                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                                    </svg>
                                                    <span class="mt-2 block text-sm text-gray-800 dark:text-gray-200">
                                                        Browse your device or <span class="group-hover:text-gray-700 text-gray-600">drag 'n drop'</span>
                                                    </span>
                                                    <span class="mt-1 block text-xs text-gray-500">
                                                        Supported file types: .mp3, .wav, .mpeg, .m4a
                                                    </span>
                                                    <span class="mt-1 block text-xs text-gray-500 overflow-hidden overflow-ellipsis">
                                                        {selectedFile && selectedFile.name}
                                                    </span>

                                                </label> */}

                                                <div class="flex w-full mb-1 h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700">
                                                    <div class="flex flex-col justify-center overflow-hidden bg-gray-500 transition-width duration-300 ease-in-out"
                                                        role="progressbar"
                                                        style={{ width: `${progress}%` }}
                                                        aria-valuenow={progress}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="space-y-2">
                                                {/* <label for="af-submit-app-upload-session" class="flex inline-block text-sm font-medium text-gray-800 dark:text-gray-200">
                                                    Manully edit
                                                </label> */}

                                                <div class="gap-2">
                                                    <label for="hs-checkbox-in-form" class="cursor-pointer flex p-3 w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-gray-500 focus:ring-gray-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
                                                        <input type="checkbox" onChange={(e) => setIsCheckedManualEdit(e.target.checked)} class="shrink-0 mt-0.5 border-gray-200 rounded text-gray-800 focus:ring-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-gray-500 dark:checked:border-gray-500 dark:focus:ring-offset-gray-800" id="hs-checkbox-in-form" />
                                                        <span class="text-sm text-gray-500 ms-3 dark:text-neutral-400">Manually edit recap before generating</span>
                                                    </label>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="mt-5 flex justify-end gap-x-2">

                                            {!uploading && (
                                                <button onClick={close} type="button" class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                                    Cancel
                                                </button>
                                            )}
                                            <button id="session-submit-btn" type="submit" disabled={!currUser} class=" py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-black text-white hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">

                                                {/* If not uploading, render Submit else render spinner */}

                                                {currUser ? buttonText : 'Sign up to generate'}
                                                {uploading && currUser && <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading"></div>}
                                                                                          
                                            </button>
                                        </div>
                                    </div>


                                </form>
                            </Menu.Items>

                        </Transition>
                    </>
                )}

            </Menu>
        </div>
    )
}

export default UploadSession;