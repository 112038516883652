import React, { useState, useEffect, useRef } from 'react';
import TextBox from '../components/TextBox';

import { BiSolidCopy } from 'react-icons/bi';

import LandingPageGraphicV2 from '../assets/LandingPageGraphicV2.svg';
import ScrybeLogo from '../assets/Scrybe_Logo.svg';

import video_demo_1 from '../assets/video_demo_1.mp4';
import video_demo_2 from '../assets/video_demo_2.mp4';
import video_demo_3 from '../assets/video_demo_3.mp4';
import video_demo_4 from '../assets/video_demo_4.mp4';
import video_demo_5 from '../assets/video_demo_5.mp4';
import video_demo_6 from '../assets/video_demo_6.mp4';
import video_demo_7 from '../assets/video_demo_7.mp4';
import video_demo_8 from '../assets/video_demo_8.mp4';
import video_demo_9 from '../assets/video_demo_9.mp4';
import video_demo_10 from '../assets/video_demo_10.mp4';


import AudioDemo from '../components/AudioDemo';
import Steps from '../components/Steps';
import Hero from '../components/Hero';
import Pricing from '../components/Pricing';

import { Link, useNavigate } from 'react-router-dom';


const HomePage = () => {

    window.scrollTo(0, 0);

    const audioDemoRef = useRef(null);
    const scrollToAudioDemo = () => {  // Step 3: Create a scroll function
        audioDemoRef.current.scrollIntoView({ behavior: 'smooth' });

    };

    const sample_text_1 =
        `Last session you found yourselves in the small rural town of Trostenwald, attending the Fletching and Moondrop Travelling Carnival of Curiosities. Amidst your wonder at the assortment of acts and performances, chaos ensued when an old man in the audience transformed into a terrifying undead creature, subsequently attacking bystanders and creating another monstrosity. 
    As the crowd devolved into panic and people fled the macabre scene, you all leapt into action, battling the zombies and successfully ended the threat. The aftermath, however, left you in an uneasy position as the local crownsguard arrived, led by Watchmaster who placed you under suspicion and investigation for the events. 
    You were cautioned against leaving the city, and were told that any attempt to do so would result in the crownsguard pursuing you with the full might of the Cerberus Assembly. As you were being questioned, one of you mislead a guard to fetch the young singing dwarf Toya, only to use the opportunity to flee from the scene. 
    The session ended with you standing within the tense atmosphere of the tent, caught between proving your innocence and discovering exactly how and why these transformations occurred.`;


    const sample_text_2 =
        `Last session, you boarded a ship on April 12th, 1926, with the intention of selling books and getting cars appraised. During the voyage, the ship sank, and you found yourselves stranded on Beacon Island following a storm. Upon reaching the shore, you discovered the bodies of the individuals you met on the ship. Seeking shelter, you arrived at a lighthouse cottage and began to investigate.
    Inside, you found evidence of disturbance, a broken key, a warm mug of coffee, and a trail of blood leading from the staircase to the kitchen. A journal revealed that George Cassidy, presumably the lighthouse keeper, had discovered strange coins and feared for his safety due to a paranoid shipmate named Michael. During a tense night with strange creatures scratching and clawing, you defended yourselves and survived until morning.
    You attempted to use the radio to call for help, only to be met with skepticism. Confronted by strange fish-like creatures, you fought them off and barricaded yourselves in the larder. Despite the bizarre and terrifying experiences, you both woke the next morning to a rescue team at the door. The evidence of the creatures had vanished, save for the strange spine that had been fired at you. The session concluded with you safe but shaken, armed with the spine as the sole piece of tangible evidence of the night's horrors.`;

    const sample_text_3 =
        `Last session, you began at the River Shining Tavern in Daggerford where you met and were employed by a group of Vistani, led by Stanimir, to aid them in a mysterious quest. You agreed to help and set off with them toward the land of Barovia after being promised a substantial reward from the town's Burgomaster for dealing with a vampire threat.
    Traveling with the Vistani caravan, you experienced a strange evening by the fire with Stanimir narrating a haunting story of a dark past and a tormented prince. Resting for the night, you encountered a bewildering phenomenon as you awoke to find yourselves abandoned and alone, with no trace of your Vistani companions.
    Determined to understand your circumstances, you ventured forth and stumbled upon a rather grisly scene—a half-buried corpse clutching a letter from the Burgomaster of Barovia, pleading for help against a vampire that has menaced the land for centuries.
    As you were processing this dire request for aid, you were ambushed by ferocious wolves. You bravely stood your ground and defended yourselves fiercely, only to witness the slain beasts horrifically transform into human forms posthumously, revealing their true nature as werewolves—a terrifying revelation to you both.
    Amidst this chaos, you noticed a massive, ominous wolf with glowing red eyes observing the battle, which later dispersed the other attackers with a mere gesture before retreating into the mist, leaving you perplexed and alarmed.
    Bruised, bitten, and bewildered, you called upon Borin's celestial war boar, Zoltan, although now it appeared gruesomely deformed. Despite the unsettling transformation of your steed, you rode on and eventually, after surveying the unsettling, mist-veiled lands, you finally arrived at the outskirts of a seemingly desolate village, a place that bore the name of Barovia, matching the details of the letter you found earlier.`

    const sample_text_4 =
        `Last session, you all arrived at the Fletching and Moondrop Traveling Carnival of Curiosities in Trostenwald, excited to experience the various acts and amusements provided by its colorful performers. As the show commenced, you were captivated by the performances of Mona and Yuli, the Knot Sisters, followed by Orna, the Fire Fairy, whose fiery display left you in awe.
    However, the carnival took a dark turn when a seemingly mundane attendee transformed into a monstrous creature right before your eyes. Panic ensued as the transformed man attacked, leading to chaos that quickly enveloped the carnival grounds. You leaped into action, contending not only with the initial monstrous transformation but also a subsequent one, as it became apparent that whatever afflicted the first man quickly spread to another.
    In a display of bravery and quick thinking, you managed to subdue the threats, restoring a semblance of order amidst the chaos. Despite your heroic efforts, the town's Crownsguard arrived, and the situation took another turn. The carnival's master, Gustav, along with others associated with the carnival, were placed under arrest by the local authorities, suspecting them to be behind the grotesque transformations.
    Caught in the aftermath of the night's terrifying events and amid the Crownsguard's suspicion, you found yourselves under scrutiny, your allegiance in question despite your actions to protect the carnival's attendees. The session concluded with you being escorted out of the tent by the Crownsguard, leaving the future of the carnival and your own fates uncertain.`

    const sample_text_6 =
        `Last session, you arrived at the dwarven city of Kraghammer, seeking the whereabouts of Lady Kima of Vord, who had vanished while on a vision quest in the dangerous depths below the city. After navigating your way through the city and dealing with its various denizens, you discovered that Lady Kima had ventured into the Greyspine Mines, driven by a fervent impulse to confront some unnamed evil she believed was lurking there.
    Determined to find her or at least uncover what she had found, you decided to seek out Nostoc Greyspine, the overseer of the mines, hoping he could grant you access or information. Along the way, you encountered and dealt with a number of challenges, including persuading the guards to let you pass, engaging in a bit of deception, and ultimately proving your combat prowess when you were ambushed by terrifying creatures emerging from the mines.
    In a significant confrontation, you faced a horrifying naga abomination, a creature stitched together from multiple bodies, showcasing the dreadful reality of what lay in the depths of the mines. With great effort and teamwork, you managed to defeat the creature, earning not only the wary respect of the dwarven guards but also perhaps a clue to the greater dangers that lurk below Kraghammer.
    Armed with this knowledge and determined to proceed, you prepared to delve deeper into the mines in search of Lady Kima and the source of the evil she sought to confront.`

    return (
        <div className="min-h-screen flex flex-col">
            <div className="max-w-7xl mx-auto p-8 flex-grow">

                <Hero onButtonClick={scrollToAudioDemo} />

                <div class="px-4 sm:px-6 lg:px-8 py-10 sm:py-12 lg:py-16">

                    <h2 class="font-inknut font-medium text-2xl text-gray-800 md:text-3xl dark:text-gray-200 text-center mb-5 md:mb-10 ">
                        Transform Your TTRPG Sessions Into Epic Narrated Recaps
                    </h2>
                    <Steps />
                </div>


                <div class="px-4 sm:px-6 lg:px-8 py-10 sm:py-12 lg:py-16 scroll-mt-28" ref={audioDemoRef}>

                    <h2 class="font-inknut font-medium text-2xl text-gray-800 md:text-3xl dark:text-gray-200 text-center mb-5 md:mb-10">
                        Sample Recaps Generated with Scrybe
                    </h2>
                    <div className='space-y-36 md:space-y-10'>
                        <AudioDemo id="video-demo" video_source={video_demo_5} title="Recap: Curious Beginnings" text={sample_text_4} />
                        <AudioDemo id="video-demo" video_source={video_demo_6} title="Recap: Arrival at Kraghammer" text={sample_text_6} reversed />
                        <AudioDemo id="video-demo" video_source={video_demo_7} title="Recap: The Lighthouse" text={sample_text_2} />

                        {/* <div class="flex flex-wrap justify-center gap-10">
                            <video
                                src={video_demo_10}
                                class="w-full md:max-w-[550px] rounded-4xl shadow-xl"
                                controls
                                preload="metadata"
                                alt="Scrybe recap demo video."
                            ></video>
                            <video
                                src={video_demo_8}
                                class="w-full md:max-w-[550px] rounded-4xl shadow-xl"
                                controls
                                preload="metadata"
                                alt="Scrybe recap demo video."
                            ></video>
                            <video
                                src={video_demo_9}
                                class="w-full md:max-w-[550px] rounded-4xl shadow-xl"
                                controls
                                preload="metadata"
                                alt="Scrybe recap demo video."
                            ></video>
                        </div> */}
                    </div>
                </div>

                <div class="px-4 sm:px-6 lg:px-8 py-10 sm:py-12 lg:py-16">
                    <h2 class="font-inknut font-medium text-2xl text-gray-800 md:text-3xl dark:text-gray-200 text-center mb-5 md:mb-10">
                        Pricing
                    </h2>
                    <Pricing />
                </div>



                {/* <Pricing /> */}
            </div>



        </div>

    );

};


export { HomePage };