// src/Tiptap.jsx
import React, { useState, useEffect } from 'react'
import { useEditor, EditorContent} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'


const TestPage = () => {

    const [editorContent, setEditorContent] = useState("");
    

    const editor = useEditor({
        extensions: [StarterKit],
        content: "<p>Hello World! 🌎️</p>",
        onUpdate({ editor }) {
            setEditorContent(editor.getText());
        },
    });

    useEffect(() => {
        console.log(editorContent)
    }, [editorContent])

    return (
        <>
            <EditorContent editor={editor} />
        </>
    )
}

export default TestPage