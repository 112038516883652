import React from 'react';
import { Link } from 'react-router-dom';
import ScrybeLogo from '../assets/Scrybe_Logo.svg';
import { ImQuill } from 'react-icons/im';

// Import video
import ScrybeLogoAnimation from '../assets/Scrybe_Logo_Animation.mp4';
import ScrybeVideoLoadingPlaceholder from '../assets/Scrybe_Logo_Hero_Video_Loading_Placeholder.png';



function Hero({ onButtonClick }) {

    return (
        // < !--Hero -- >
        <div class="my-[15%] md:my-0">
            {/* <!-- Grid --> */}
            <div class="grid md:grid-cols-2  md:items-center">
                <div class="flex flex-col justify-center align-center items-center">
                    {/* <!-- Title --> */}
                    <div class="max-w-2xl text-center flex items-center justify-center">
                        <h1 class="font-inknut text-5xl sm:text-7xl lg:text-8xl dark:text-gray-200">
                            Scrybe
                        </h1>
                        <div class="w-[6rem] sm:w-[7rem] lg:w-[9rem] md:hidden relative">
                            <video class="w-full h-auto object-contain mix-blend-multiply" src={ScrybeLogoAnimation} autoPlay muted playsInline disableRemotePlayback></video>
                            <img class="absolute top-0 left-0 w-full h-auto object-contain opacity-10 pointer-events-none" src={ScrybeVideoLoadingPlaceholder} alt="Scrybe logo" />
                        </div>

                    </div>

                    {/* <!-- End Title --> */}
                    <div class="mt-5 max-w-3xl text-center mx-auto">
                        <h2 class="font-inknut text-3xl lg:text-4xl text-gray-600 dark:text-gray-400 max-w-md">Epic Sessions Deserve Epic Recaps</h2>
                    </div>
                    {/* <!-- Buttons --> */}
                    <div class="mt-8 gap-3 flex justify-center">
                        <button onClick={onButtonClick} class="inline-flex whitespace-nowrap justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-black to-gray-800 hover:from-gray-800 hover:to-black border border-transparent text-white text-sm font-medium rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 py-3 px-4 dark:focus:ring-offset-gray-800" href="#video-demo">
                            View sample
                            <svg class="flex-shrink-0 w-4 h-4" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
                            </svg>
                        </button>
                        <Link to="/pricing" class="relative whitespace-nowrap group p-2 ps-3 inline-flex items-center gap-x-2 text-sm rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                            Get started
                        </Link>
                    </div>
                    {/* <!-- End Buttons --> */}

                </div>
                {/* <!-- End Col --> */}

                <div class="aspect-w-9 aspect-h-9 ms-4 hidden md:block h-full">
                    <video class="w-full h-full object-cover top-0 left-0" src={ScrybeLogoAnimation} autoPlay muted playsInline disableRemotePlayback style={{ imageRendering: 'auto' }}></video>
                    <img class="absolute top-0 left-0 w-full h-full object-cover opacity-10 pointer-events-none" src={ScrybeVideoLoadingPlaceholder} alt="Scrybe logo" />
                </div>

                {/* <div class="relative ms-4 hidden md:block h-full">
                    <video class="w-full relative top-0 left-0" src={ScrybeLogoAnimation} autoPlay muted playsInline disableRemotePlayback style={{ imageRendering: 'auto' }}></video>
                </div> */}

                {/* <!-- End Col --> */}
            </div>
            {/* <!-- End Grid --> */}
        </div>
    );
}

export default Hero;
