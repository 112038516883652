import React from 'react';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as Icon } from '../assets/Scrybe_Logo.svg';
import { FaCanadianMapleLeaf, FaXTwitter, FaYoutube } from "react-icons/fa6";
import { FaRedditAlien } from "react-icons/fa";
import { LuDot } from "react-icons/lu";




const Footer = () => {
    return (
        <body class="bg-black">
            <footer class="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
                <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">



                    {/* <div>
                        <h4 class="text-xs font-semibold text-white uppercase dark:text-white">Product</h4>

                        <div class="mt-3 grid space-y-3 text-sm">
                            <p><a class="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white" href="#">Pricing</a></p>
                        </div>
                    </div> */}


                </div>

                <div class="pt-5 mt-5 border-t border-gray-200 dark:border-gray-700">
                    <div class="flex flex-col items-center md:flex-row sm:justify-between sm:items-center gap-2">
                        <div class="space-x-3 md::space-x-4 text-sm text-center">
                            <Link to="terms-of-service" class="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white">Terms</Link>
                            <Link to="privacy-policy" class="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white">Privacy</Link>
                            <Link to="/faq" class="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white">FAQ</Link>
                            <Link to="/pricing" class="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white">Pricing</Link>
                            <Link to="/credit" class="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white">Music Credit</Link>
                            <Link to="/contact" class="inline-flex gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white">Contact</Link>
                        </div>

                        <div class="flex space-x-4 items-center text-sm">
                            <div className='flex items-center space-x-1'>
                                <Link to="/" class="inline-flex whitespace-nowrap gap-x-2 text-white hover:text-white dark:text-white dark:hover:text-white" >© 2024 Scrybe</Link>
                                <FaCanadianMapleLeaf class="w-3 h-3 text-white" />
                            </div>
                            <LuDot class="w-4 h-4 text-white" />
                            <a class="inline-block text-white hover:text-white dark:hover:text-white" href="https://twitter.com/ScrybeAI">
                                <FaXTwitter class="w-4 h-4" />
                            </a>
                            <a class="inline-block text-white hover:text-white dark:hover:text-white" href="https://www.reddit.com/user/ScrybeSquid/">
                                <FaRedditAlien class="w-4 h-4" />
                            </a>
                            <a class="inline-block text-white hover:text-white dark:hover:text-white" href="https://www.youtube.com/@ScrybeQuill">
                                <FaYoutube class="w-4 h-4" />
                            </a>
                        </div>

                    </div>
                </div>
            </footer>
        </body>
    );
};

export default Footer;