import { GiVial, GiChemicalDrop, GiDrinkMe } from "react-icons/gi";
import { FaDroplet, FaVial, FaFlask, FaBottleDroplet } from "react-icons/fa6";
import { useState, useEffect } from 'react';

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, storage, db } from '../firebaseConfig';
import { BiLogIn, BiLogOut } from 'react-icons/bi';
import { getDoc, doc, addDoc, setDoc, collection, onSnapshot } from "firebase/firestore";
import { Link, useNavigate } from 'react-router-dom';

const handleGoogle = async (e) => {
    const provider = await new GoogleAuthProvider();
    await signInWithPopup(auth, provider).then(async (result) => {
        // Add user to firestore database
        const user = result.user;

        const userRef = doc(db, `users`, user.uid);

        // Check if user exists in database
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            console.log('User already exists');
            return;
        }

        await setDoc(userRef, { tokenBalance: 1 }, { merge: true });
        await setDoc(userRef, { email: user.email }, { merge: true });
        await setDoc(userRef, { displayName: user.displayName }, { merge: true });

    }
    ).catch((error) => {
        console.log(error);
    }
    );
}

async function getStripeUrl(tier) {

    const endpoint = `https://us-central1-dungeon-copilot-6065c.cloudfunctions.net/create_stripe_session_${tier}`;

    console.log("Sending request to: ", endpoint);

    console.log("Current user ID: ", auth.currentUser.uid);



    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userID: auth.currentUser.uid,
        }),
    });

    const result = await response.json();

    console.log("Response: ", result);

    if (response.ok) {
        console.log("Stripe payment URL generated: ", result.checkoutURL);
    } else {
        console.error("Error generating Stripe payment URL: ", result);
        // Update UI based on error
    }

    return result.checkoutURL;

}


function Pricing() {

    const [purchaseButtonStates, setPurchaseButtonStates] = useState({
        droplet: "Purchase",
        vial: "Purchase",
        inkwell: "Purchase"
    });

    const [loadingStates, setLoadingStates] = useState({
        droplet: false,
        vial: false,
        inkwell: false
    });

    const handlePurchaseClick = async (tier) => {
        try {
            // Check if user is signed in
            setLoadingStates(prevStates => ({ ...prevStates, [tier]: true }));
            if (!auth.currentUser) {
                // If user is not signed in, trigger the Google sign-in process
                await handleGoogle();

            }

            if (!auth.currentUser) {
                setLoadingStates(prevStates => ({ ...prevStates, [tier]: false }));
                return;
            }


            // Mark the clicked button as loading

            const stripeUrl = await getStripeUrl(tier); // Fetch the Stripe URL

            if (stripeUrl) {
                // If URL is successfully received, open it in a new tab
                window.location.href = stripeUrl;
            }
        } catch (error) {
            console.error("Error during purchase:", error);
        } finally {
            // Reset the loading state of the clicked button
            setLoadingStates(prevStates => ({ ...prevStates, [tier]: false }));
        }
    };

    // const [currUser, setCurrUser] = useState(null);

    // useEffect(() => {
    //     const unsubscribe = auth.onAuthStateChanged(user => {
    //         if (user) {
    //             setCurrUser(user);
    //         } else {
    //             setCurrUser(null);
    //         }
    //     });

    //     return unsubscribe;
    // }, []);


    return (
        <div>

            {/* <!-- Pricing --> */}

            {/* <!-- Grid --> */}
            <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-6 lg:items-center">
                {/* <!-- Card --> */}
                <div class="flex flex-col h-full justify-between border border-gray-200 text-center rounded-xl p-8 dark:border-gray-700">
                    <div class="flex flex-col">
                        <h4 class="font-medium text-lg text-gray-800 dark:text-gray-200 font-inknut">Free</h4>
                        <FaDroplet class="mt-5 text-5xl text-gray-800 dark:text-gray-200 mx-auto" />
                        <span class="mt-5 font-bold text-5xl text-gray-800 dark:text-gray-200">
                            $0
                        </span>
                        <p class="mt-2 text-sm text-gray-500">Perfect for those dipping their quill for the first time</p>
                        <ul class="mt-7 space-y-2.5 text-sm text-left">
                            <li class="flex space-x-2">
                                <svg class="flex-shrink-0 mt-0.5 h-4 w-4 text-gray-600 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                                <span class="text-gray-800 dark:text-gray-400">
                                    1 free ink drop! (applied on account creation)
                                </span>
                            </li>
                        </ul>
                    </div>


                    <div class="flex flex-col">
                        <button onClick={handleGoogle} class="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-gray-900 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                            Sign up
                        </button>
                        <div class="flex justify-center mt-2 text-sm">
                            <span class="text-gray-500 dark:text-gray-400">
                                (1 recap)
                            </span>
                        </div>
                    </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                {/* <div class="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-gray-700">
                    <h4 class="font-medium text-lg text-gray-800 dark:text-gray-200">Droplet</h4>
                    <FaDroplet class="mt-5 w-6 text-5xl text-gray-800 dark:text-gray-200 mx-auto" />
                    <span class="mt-5 font-bold text-5xl text-gray-800 dark:text-gray-200">
                        $3
                    </span>
                    <p class="mt-2 text-sm text-gray-500">Enough to transcrybe a single adventure</p>

                    <ul class="mt-7 space-y-2.5 text-sm">
                        <li class="flex space-x-2">
                            <svg class="flex-shrink-0 mt-0.5 h-4 w-4 text-gray-600 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                            <span class="text-gray-800 dark:text-gray-400">
                                1 ink drop
                            </span>
                        </li>
                    </ul>

                    <button class={`mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent ${loadingStates.droplet ? 'bg-gray-400' : 'bg-gray-100'} text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-gray-900 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`} onClick={() => handlePurchaseClick("droplet")}>
                        {loadingStates.droplet ? (
                            <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-black rounded-full" role="status" aria-label="loading"></div>
                        ) : (
                            "Purchase"
                        )}
                    </button>
                </div> */}
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div class="flex flex-col relative h-full justify-between border-2 border-gray-600 text-center shadow-xl rounded-xl p-8 dark:border-gray-700">
                    <div class="flex flex-col">
                        <span class="absolute left-[50%] translate-x-[-50%] top-[-15px] whitespace-nowrap inline-flex items-center gap-1.5 py-1.5 px-3 rounded-lg text-xs uppercase font-semibold bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-white">Most popular</span>
                        <h4 class="font-medium text-lg text-gray-800 dark:text-gray-200 font-inknut">Vial</h4>
                        <FaVial class="mt-5 text-5xl text-gray-800 dark:text-gray-200 mx-auto" />
                        <span class="mt-5 font-bold text-5xl text-gray-800 dark:text-gray-200">
                            $15
                        </span>
                        <p class="mt-2 text-sm text-gray-500">Replenish your inkwell</p>
                        <ul class="mt-7 space-y-2.5 text-sm text-left">
                            <li class="flex space-x-2">
                                <svg class="flex-shrink-0 mt-0.5 h-4 w-4 text-gray-600 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                                <span class="text-gray-800 dark:text-gray-400">
                                    5 ink drops
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div class="flex flex-col">
                        <button class={`mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent ${loadingStates.vial ? 'bg-gray-400' : 'bg-gray-100'} text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-gray-900 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`} onClick={() => handlePurchaseClick("vial")}>
                            {loadingStates.vial ? (
                                <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-black rounded-full" role="status" aria-label="loading"></div>
                            ) : (
                                "Purchase"
                            )}
                        </button>
                        <div class="flex justify-center mt-2 text-sm">
                            <span class="text-gray-500 dark:text-gray-400">
                                (5 recaps)
                            </span>
                        </div>
                    </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div class="flex flex-col h-full border border-gray-200 text-center rounded-xl p-8 dark:border-gray-700">
                    <div class="flex flex-col">
                        <h4 class="font-medium text-lg text-gray-800 dark:text-gray-200 font-inknut">Inkwell</h4>
                        <FaFlask class="mt-5 text-5xl text-gray-800 dark:text-gray-200 mx-auto" />
                        <span class="mt-5 font-bold text-5xl text-gray-800 dark:text-gray-200">
                            $30
                        </span>
                        <p class="mt-2 text-sm text-gray-500">Enough ink to last an adventure</p>
                        <ul class="mt-7 space-y-2.5 text-sm text-left">
                            <li class="flex space-x-2">
                                <svg class="flex-shrink-0 mt-0.5 h-4 w-4 text-gray-600 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                                <span class="text-gray-800 dark:text-gray-400">
                                    10 ink drops
                                </span>
                            </li>
                            <li class="flex space-x-2">
                                <svg class="flex-shrink-0 mt-0.5 h-4 w-4 text-gray-600 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                                <span class="text-gray-800 dark:text-gray-400">
                                    +2 free bonus ink drops
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div class="flex flex-col">
                        <button class={`mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent ${loadingStates.inkwell ? 'bg-gray-400' : 'bg-gray-100'} text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-gray-900 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`} onClick={() => handlePurchaseClick("inkwell")}>
                            {loadingStates.inkwell ? (
                                <div class="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-black rounded-full" role="status" aria-label="loading"></div>
                            ) : (
                                "Purchase"
                            )}
                        </button>
                        <div class="flex justify-center mt-2 text-sm">
                            <span class="text-gray-500 dark:text-gray-400">
                                (12 recaps)
                            </span>
                        </div>
                    </div>
                </div>
                {/* <!-- End Card --> */}
            </div>
            {/* <!-- End Grid --> */}

            {/* <!-- End Pricing --> */}
        </div>

    );
}

export default Pricing;
